import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Settings } from '../../models/settings/Settings';
import { RequestProvider } from '../request/request';
import { SessionService } from 'src/app/state/session/session.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class PreferencesProvider {

  settings: Settings = undefined;

  constructor(
    public request: RequestProvider,
    private storage: StorageService,
    public sessionService: SessionService
  ) {
    this.settings = new Settings();
  }

  setValue(key: string, value: any, global: boolean) {

    this.storage.set(key, value);

    if (global) {
      this.request.sendPost({
        api: 'settings',
        mode: 'set',
        key,
        value
      }).subscribe();
    }

    this.sessionService.update(key, value);
  }

  getValue(key: string, global: boolean): Observable<any> {

    if (global) {

      return this.request.sendPost({
        api: 'settings',
        mode: 'get',
        key
      }).pipe(
        map(value => {

          if (value['body'][key] != null) {
            return value['body'][key];
          } else {
            return this.storage.get(key);
          }
        })
      );
    } else {

      return new Observable(observer => {

        this.storage.get(key).then(value => {

          observer.next(value);
        }).catch((error) => {

          observer.error(error);
        });
      });
    }
  }

  init() {
    
    Object.keys(this.settings).forEach(key => {
      this.getValue(key, true).subscribe((value: any) => {
        this.settings[key] = value;
        this.setValue(key, value, false);
      });
    });
  }
}
