
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Feedback } from '../../models/contractor/feedback';
import { RequestProvider } from '../request/request';

@Injectable({
  providedIn: 'root'
})
export class FeedbackProvider {

  constructor(public request: RequestProvider) {
  }

  public getFeedback(): Observable<Feedback[]> {

    return this.request.sendPost({
      api: 'contractor',
      mode: 'feedback'
    }).pipe(
      map(data => {
        return data['body']['bewertungen'].map((feedbackData) => {
          const feedback = new Feedback(
            feedbackData.bewertung,
            feedbackData.kommentar,
            feedbackData.datum
          );
          return feedback;
        })
      })
    );
  }
}
