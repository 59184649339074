import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as Sentry from "@sentry/capacitor/";

@Injectable({
  providedIn: 'root'
})
export class RequestProvider {

  private apiUrl: string = environment.apiUrl;

  constructor(public http: HttpClient,
              public injector: Injector) {
  }

  public sendGet(url = this.apiUrl) {

    return this.http.get(url);
  }

  public sendPost(body: any, url = this.apiUrl): Observable<object> {

    const httpOptions = { headers: new HttpHeaders()};

    if (!body.pdf) {

      httpOptions.headers = httpOptions.headers.append('Content-Type', 'application/x-www-form-urlencoded');
    }

    if (body instanceof Object) {

      body = this.buildParam(body);
    }

    Sentry.addBreadcrumb({
      category: 'http',
      message: body,
      level: 'info'
    });

    return this.http.post(url, body, httpOptions);
  }

  public sendForm(formData: FormData) {

    return this.http.post(this.apiUrl, formData);
  }

  private buildParam(paramObject: object) {

    let param = '';

    Object.keys(paramObject).forEach(key => {

      param += param !== '' ? '&' : '';

      param +=  key + '=' + paramObject[key];
    });

    return param;
  }

}

export interface Response<T> {
  newToken: string;
  meta: any;
  body: T[];
}
