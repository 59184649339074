import { Injectable } from '@angular/core';
import { userStore } from './user.store';
import { User } from './user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectAllEntities, getEntity, selectManyByPredicate, selectEntitiesCount } from '@ngneat/elf-entities';
import { select } from '@ngneat/elf';

@Injectable({ providedIn: 'root' })
export class UserQuery {

  $user: Observable<User[]> = userStore.pipe(selectAllEntities());

  $activeUser: Observable<User[]> = userStore.pipe(
    selectManyByPredicate((user) => user.active === true)
  );

  $isLoading: Observable<boolean> = userStore.pipe(
    select(state => state.loading)
  );

  $usersLoaded: Observable<boolean> = userStore.pipe(
    selectEntitiesCount(),
    map(count => count > 0)
  );

  constructor() {}

  getUserById(id: number | string): User | undefined {
    return userStore.query(getEntity(id));
  }

  isUserInit(): boolean {
    return userStore.getValue().isUserInit;
  }

  isMainUser(userId: number): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const user = userStore.query(getEntity(userId));
    return user?.id === '2' && emailRegex.test(user.username);
  }
}