import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RequestProvider } from '../request/request';

@Injectable({
  providedIn: 'root'
})
export class DocumentsProvider {

  constructor(public request: RequestProvider) {
  }

  getDocuments(type: string) {

    return this.request.sendPost({
      api: 'signsAndDocuments',
      mode: type
    }).pipe(
      map(data => data['body'])
    );
  }

  sendDocumentPerMail(document: any) {

    return this.request.sendPost({
      api: 'signsAndDocuments',
      mode: 'sendPerMail',
      documentUrl: document.url,
      documentName: document.name
    }).pipe(
      map(data => data['body'])
    );
  }

}
