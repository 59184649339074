import { Injectable } from '@angular/core';
import { TokenPayload } from 'src/app/interfaces/tokenPayload.interface';
import { TranslateService } from '@ngx-translate/core';
import { NativeBiometric } from 'capacitor-native-biometric';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationProvider {

  constructor(
    private translate: TranslateService) {

  }

  createTokenPayload(token: string) {
    const parts = token.split('.');

    const tokenPayload: TokenPayload = JSON.parse(atob(parts[1]));

    return tokenPayload;
  }

  public async verifyUser() {

    const result = await NativeBiometric.isAvailable();
    const reason = this.translate.instant('app_global.biometric_title');

    if (result.isAvailable) {
      
      const isVerified = await NativeBiometric.verifyIdentity({
        reason: reason,
        title: reason,
        maxAttempts: 5,
        useFallback: true
      }).then(
        () => {
          return true;
        },
        (error) => {
          return false;
        }
      );

      return isVerified;
    } else {
      return false;
    }
  }
}
