// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.world-transfer.com/tdl_api/next/index.php',
  liveTrackingApiUrl: 'https://tracking.world-transfer.com/next/public/',
  webUrl: 'https://dev-wtdriver.world-transfer.com',
  version: '2.20.0',
  docsUrl: 'https://dev01.world-transfer.com/tdl-docs'
};

export const firebaseConfig = {
  apiKey: "AIzaSyBw2qM7qG2cN-UoQiAZKph4GYu7HnRrjIk",
  authDomain: "contractorsapp-4b0ac.firebaseapp.com",
  databaseURL: "https://contractorsapp-4b0ac.firebaseio.com",
  projectId: "contractorsapp-4b0ac",
  storageBucket: "contractorsapp-4b0ac.firebasestorage.app",
  messagingSenderId: "899412373949",
  appId: "1:899412373949:web:c9b46f6cb7d60b09b162d4",
  measurementId: "G-3XTLG2PFKK",
  vapidKey: 'BEYVaQszXX0ZXdKz81ae4yyTujYxqVj_WzAJ-dBoWMXFgmiSafmQ-yvldWwmRD1bzkCKcsz4Vf3Db-YBdU-xV9E'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
