import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export const httpLanguageInterceptor: HttpInterceptorFn = (req, next) => {

  const translate = inject(TranslateService);

  const currentLang = translate.currentLang || 'en';
    const modifiedReq = req.clone({
      headers: req.headers.set('Accept-Language', currentLang)
    });

  return next(modifiedReq);
};
