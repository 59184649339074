import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RequestProvider } from '../request/request';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class PushProvider {

  public notifications: any = {
    nonArchived: [],
    archived: []
  };

  constructor(public request: RequestProvider) {
  }

  public saveToken(token: string, deviceId: string) {

    return this.request.sendPost({
      api: 'push',
      mode: 'saveToken',
      token,
      deviceId
    }).pipe(
      map(res => res)
    );
  }

  public deleteToken(deviceId: string) {

    return this.request.sendPost({
      api: 'push',
      mode: 'deleteToken',
      deviceId
    }).pipe(
      map(res => res)
    );
  }

  public setAllMessagesToRead() {

    return this.request.sendPost({
      api: 'push',
      mode: 'setAllMessagesToRead'
    }).pipe(
      map(res => res)
    );
  }

  public loadNotifications() {

    return this.request.sendPost({
      api: 'push',
      mode: 'loadNotifications'
    }).pipe(
      map(res => this.notifications = res["body"])
    );
  }

  public deleteNotification(id: number) {

    return this.request.sendPost({
      api: 'push',
      mode: 'deleteNotification',
      id
    }).pipe(
      map(res => res)
    );
  }

  public archiveNotification(id: number) {

    return this.request.sendPost({
      api: 'push',
      mode: 'archiveNotification',
      id
    }).pipe(
      map(res => res)
    );
  }

}
