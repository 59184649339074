import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Pickup } from '../../models/notification/pickup';
import { RequestProvider } from '../request/request';

@Injectable({
  providedIn: 'root'
})
export class PickupNotificationProvider {

  public pickupNotifications: Pickup[] = [];

  constructor(public request: RequestProvider) {
  }

  public getPickupNotificationList() {

      return this.request.sendPost({
        api: 'bookings',
        mode: 'pickup_notification'
      }).subscribe(data => {

        if (!data['error']) {

          this.pickupNotifications = [];

          for (let index = 0; index < data['body']['length']; index++) {
            this.pickupNotifications.push(new Pickup(data['body'][index]));
          }
        }
      });
  }

  public sendPickupNotifications() {

    return this.request.sendPost({
      api: 'bookings',
      mode: 'pickup_notification_send'
    }).pipe(
      map(data => {

        this.pickupNotifications = [];
        return true;
      })
    );
  }

}
