import { Injectable } from '@angular/core';
import { driverEventsStore } from './driver-events.store';
import { select } from '@ngneat/elf';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DriverEventsQuery {
  driverEventState$: Observable<number> = driverEventsStore.pipe(
    select(state => state.driverEventState)
  );

  getDriverEventStateClass$ = driverEventsStore.pipe(
    select(state => {
      const driverEventState = state.driverEventState;
      if (driverEventState === 0) {
        return '';
      } else if (driverEventState === 1) {
        return 'progressButton1';
      } else if (driverEventState === 2) {
        return 'progressButton2';
      } else if (driverEventState === 3) {
        return 'progressButton3';
      } else {
        return 'progressButton';
      }
    })
  );

  driverEventStrings$ = driverEventsStore.pipe(
    select(state => {
      if (state.driverEventState >= 4) {
        return state.driverEventStrings['booking.details.progress_' + state.driverEventState];
      } else {
        return state.driverEventStrings['booking.details.progress_' + (state.driverEventState + 1)];
      }
    })
  );

  constructor() { }

  getDriverEventState(): number {
    return driverEventsStore.getValue().driverEventState;
  }
}