import { Injectable } from '@angular/core';
import { RequestProvider } from 'src/app/services/request/request';
import { BoundingBox, Zone, ZoneGroup } from './location.utils';
import { LocationUtils } from './location.utils';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ZoneService {
    constructor(private request: RequestProvider) { }

    /**
     * Lädt die Zonen basierend auf dem Typ und Standort.
     */
    loadZones(type: 'pickup' | 'dropoff', location: { lat: number, lon: number, isAirport: boolean, iata: string }, defaultRadius: number): Observable<ZoneGroup> {
        if (!location.isAirport) {
            const singleZone: Zone = {
                lat: location.lat,
                lon: location.lon,
                radius: defaultRadius
            };
            const zoneGroup: ZoneGroup = {
                boundingBox: this.createBoundingBox([singleZone]),
                zones: [singleZone]
            };
            return of(zoneGroup);
        }

        return this.request.sendPost({
            api: 'bookings',
            mode: 'get_live_tracking_zones',
            iata: location.iata,
        }).pipe(
            map((response: { body: any[] }) => {
                if (response && response.body && response.body.length > 0) {
                    const zones: Zone[] = response.body.map((zone: { lat: string | number, lon: string | number, radius: string | number }) => ({
                        lat: Number(zone.lat),
                        lon: Number(zone.lon),
                        radius: Number(zone.radius)
                    })).filter(zone => !isNaN(zone.lat) && !isNaN(zone.lon) && !isNaN(zone.radius));

                    if (zones.length === 0) {
                        throw new Error('Keine gültigen Zonen nach der Konvertierung gefunden.');
                    }

                    return {
                        boundingBox: this.createBoundingBox(zones),
                        zones
                    } as ZoneGroup;
                } else {
                    throw new Error('Keine Zonen im Response gefunden.');
                }
            }),
            catchError(error => {
                console.error(`Fehler beim Laden der Zonen für ${type}:`, error);

                const fallbackZone: Zone = {
                    lat: location.lat,
                    lon: location.lon,
                    radius: defaultRadius
                };
                const fallbackGroup: ZoneGroup = {
                    boundingBox: this.createBoundingBox([fallbackZone]),
                    zones: [fallbackZone]
                };
                return of(fallbackGroup);
            })
        );
    }

    /**
     * Erstellt eine Bounding Box basierend auf den Zonen.
     */
    public createBoundingBox(zones: Zone[]): BoundingBox {
        if (!zones || zones.length === 0) {
            throw new Error("Keine Zonen zum Erstellen der Bounding Box bereitgestellt.");
        }

        let minLat: number, maxLat: number, minLon: number, maxLon: number;

        if (zones.length === 1) {
            const zone = zones[0];
            const deltaLat = LocationUtils.calculateDelta(zone.radius, zone.lat);
            const deltaLon = LocationUtils.calculateDelta(zone.radius, zone.lat);
            minLat = zone.lat - deltaLat;
            maxLat = zone.lat + deltaLat;
            minLon = zone.lon - deltaLon;
            maxLon = zone.lon + deltaLon;
        } else {
            const firstZone = zones[0];
            const firstDeltaLat = LocationUtils.calculateDelta(firstZone.radius, firstZone.lat);
            const firstDeltaLon = LocationUtils.calculateDelta(firstZone.radius, firstZone.lat);
            minLat = firstZone.lat - firstDeltaLat;
            maxLat = firstZone.lat + firstDeltaLat;
            minLon = firstZone.lon - firstDeltaLon;
            maxLon = firstZone.lon + firstDeltaLon;

            zones.forEach(zone => {
                const deltaLat = LocationUtils.calculateDelta(zone.radius, zone.lat);
                const deltaLon = LocationUtils.calculateDelta(zone.radius, zone.lat);

                const zoneMinLat = zone.lat - deltaLat;
                const zoneMaxLat = zone.lat + deltaLat;
                const zoneMinLon = zone.lon - deltaLon;
                const zoneMaxLon = zone.lon + deltaLon;

                if (zoneMinLat < minLat) minLat = zoneMinLat;
                if (zoneMaxLat > maxLat) maxLat = zoneMaxLat;
                if (zoneMinLon < minLon) minLon = zoneMinLon;
                if (zoneMaxLon > maxLon) maxLon = zoneMaxLon;
            });
        }

        const center = {
            lat: (minLat + maxLat) / 2,
            lon: (minLon + maxLon) / 2
        };

        const maxDistanceFromCenter = LocationUtils.calculateMaxDistanceFromCenter({ minLat, minLon, maxLat, maxLon, center, 'maxDistanceFromCenter': 0 });

        return { minLat, minLon, maxLat, maxLon, center, maxDistanceFromCenter };
    }
}