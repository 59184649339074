import {
    Animation,
    mdTransitionAnimation,
    NavOptions
} from '@ionic/core';
import { pageTransition } from './iosTransition';

export interface TransitionOptions extends NavOptions {
    progressCallback?: (ani: Animation | undefined) => void;
    baseEl: any;
    enteringEl: HTMLElement;
    leavingEl: HTMLElement | undefined;
}
  
export function customTransition(navEl: HTMLElement, opts: TransitionOptions): Animation {    
    if (opts.mode === 'ios') {
        return pageTransition(navEl, opts);
    }

    return mdTransitionAnimation(navEl, opts);
}