import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { BookingProvider } from '../services/booking/booking';
import { Booking } from '../models/booking/booking';
import { map } from 'rxjs/operators';
import { DialogService } from '../services/dialog/dialog.service';
import { SessionQuery } from '../state/session/session.query';
import { PermissionLevel } from '../state/user/user.model';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class BookingDetailsGuard  {

  constructor(
    public translate: TranslateService,
    private bookingService: BookingProvider,
    private dialogService: DialogService,
    private SessionQuery: SessionQuery) {
  }

  canDeactivate(component: CanComponentDeactivate,
                route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return component.canDeactivate ? component.canDeactivate() : true;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let accessLevel = this.SessionQuery.getUserAccessLevel();

    if (route.paramMap.get('bookingSegment') === 'unconfirmed' && accessLevel !== 'driver') {
      return this.bookingService.searchBookingByCode(route.paramMap.get('bookingId')).pipe(
        map((booking: Booking) => {
          const cachedBooking: Booking = this.bookingService.getBooking(route.paramMap.get('bookingId'), 'unconfirmed');

          if (booking && (booking?.voucher_id === '0' || booking?.buch_id.includes('_')) || cachedBooking && cachedBooking?.roletype_ident !== 1) {
            return true;
          } else {

            this.translate.get(['guards.booking.not_available_header', 'guards.booking.not_available_message']).subscribe(values => {
              this.dialogService.showAlert({
                header: values['guards.booking.not_available_header'],
                message: values['guards.booking.not_available_message'],
                buttons: ['Ok']
              });
            });

            return false;
          }
        })
      );
    }

    return true;
  }
}
