import { Injectable } from '@angular/core';
import { sessionStore } from './session.store';
import { map, tap, catchError } from 'rxjs/operators';
import { RequestProvider } from 'src/app/services/request/request';
import { Observable, of as observableOf } from 'rxjs';
import { TokenPayload } from 'src/app/interfaces/tokenPayload.interface';
import { AuthenticationProvider } from 'src/app/services/authentication/authentication';
import { BookingProvider } from 'src/app/services/booking/booking';
import { StorageService } from 'src/app/services/storage/storage.service';
import { Device } from '@capacitor/device';
import { setProps } from '@ngneat/elf';

@Injectable({ providedIn: 'root' })
export class SessionService {

  constructor(
    private storage: StorageService,
    private request: RequestProvider,
    private authenticationProvider: AuthenticationProvider,
    public bookingProvider: BookingProvider
  ) { }

  async getSettings() {
    const settings: { [key: string]: any } = {};

    const result = await this.storage.forEach();

    if (!result.some(setting => setting.key === 'isOffline')) {
      settings['isOffline'] = 'false';
      this.storage.set('isOffline', 'false');
    }

    for (const setting of result) {
      settings[setting.key] = setting.value;
    }

    sessionStore.update(setProps({ settings, settingsLoaded: true }));
  }

  update(key: string, value: any) {
    const currentSettings = { ...sessionStore.getValue().settings };
    currentSettings[key] = value;
    sessionStore.update(setProps({ settings: currentSettings }));
    this.storage.set(key, value);
  }

  login(username: string, password: string, gtu: string): Observable<string | object> {
    return this.request.sendPost({
      api: 'users',
      mode: 'login',
      gtu,
      username,
      password
    }).pipe(
      tap(token => {
        if (token) {
          this.setTokenPayload(String(token));
          this.storage.set('jwt', token);
          sessionStore.update(setProps({ token: String(token) }));
        }
      })
    );
  }

  tokenLogin() {
    const token = sessionStore.getValue().settings['jwt'];
    if (token) {
      this.setTokenPayload(token);
      sessionStore.update(setProps({ token }));
    }
  }

  setTokenPayload(token: string) {
    const tokenPayload: TokenPayload = this.authenticationProvider.createTokenPayload(token);
    sessionStore.update(setProps({ tokenPayload }));
  }

  logout() {
    this.bookingProvider.deleteCachedBookings();
    this.update('jwt', undefined);
    sessionStore.update(setProps({ token: undefined, tokenPayload: undefined }));
  }

  async setLoginActivityEntry() {
    return this.request.sendPost({
      api: 'users',
      mode: 'setLoginActivity',
      uuid: (await Device.getId()).identifier
    }).pipe(
      map(data => data.toString()),
      catchError((e: any) => observableOf(e))
    ).subscribe();
  }

  getComplaintsCounter() {
    return this.request.sendPost({
      api: 'complaints',
      mode: 'complaintCounter'
    }).pipe(
      tap(complaintsCounter => {
        if (complaintsCounter) {
          sessionStore.update(
            setProps({ complaintCount: complaintsCounter['body']['complaint_count'] })
          );
        }
      })
    ).subscribe();
  }

  getNonWorkingEmailValue() {
    return this.request.sendPost({
      api: 'contractor',
      mode: 'checkIfEmailIsDead'
    }).pipe(
      tap(isContactEmailDead => {
        if (isContactEmailDead) {
          sessionStore.update(
            setProps({ isContactEmailDead: isContactEmailDead['body']['isContactEmailDead'] })
          );
        }
      })
    ).subscribe();
  }
}