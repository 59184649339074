import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Contractor } from '../../models/contractor/contractor';
import { RequestProvider } from '../request/request';

@Injectable({
  providedIn: 'root'
})
export class ContractorProvider {

  private contractor: Contractor = null;

  constructor(public request: RequestProvider) {
  }

  public getContractor(): Observable<Contractor> {

      return this.request.sendPost({
        api: 'contractor',
        mode: 'contractorData'
      }).pipe(
        map(data => {

          this.contractor = new Contractor();
          this.contractor.init(data['body']['contractor']);

          return this.contractor;
        })
      );
  }
}
