import { enableProdMode} from '@angular/core';
import { environment } from './environments/environment';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import * as Sentry from "@sentry/capacitor/";
import { init as SentryAngularInit, instrumentAngularRouting, BrowserTracing, Replay} from "@sentry/angular-ivy";
import { HttpErrorResponse } from '@angular/common/http';

if (environment.production) {
    enableProdMode();
    window.console.warn = () => { };
}

if (environment.production === true) {

    Sentry.init({
      dsn: 'https://6645424ab2c2420ab572192089aefcc4@sentry.io/1396550',
      release: 'wt-driver@' + environment.version,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: instrumentAngularRouting
        }),
        new Replay({
          blockAllMedia: true,
          networkDetailAllowUrls: [ window.location.origin, "api.world-transfer.com"],
          beforeErrorSampling(event) {
            return !event?.message?.includes('401 Unauthorized');
          },
        })
      ],
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 0.4,
      environment: environment.production ? "production" : "development",
      beforeSend(event, hint) {
        const error = hint.originalException;
        
        if (isHttpErrorResponse(error)) {
          if (error?.status === 401 && error?.statusText === 'Unauthorized') {
            return null;
          }
        }
        if (event?.message?.includes('401 Unauthorized')) {
          return null;
        }

        return event;
      }
      },
      SentryAngularInit
    );
  }

  function isHttpErrorResponse(error: unknown): error is HttpErrorResponse {
    return (error as HttpErrorResponse).message !== undefined;
  }

bootstrapApplication(AppComponent, appConfig)
    .catch((err) => console.log(err));

defineCustomElements(window);
