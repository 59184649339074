import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular/standalone';
import { AlertOptions, ToastOptions } from '@ionic/core';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    private defaultAlertOptions: AlertOptions;
    private defaultToastOptions: ToastOptions;

    constructor(
        public alertController: AlertController,
        public toastController: ToastController
    ) { }

    async showAlert(alertOptions: AlertOptions) {
        const option = this.createOptions(alertOptions, 'alert');        
        const alert = await this.alertController.create(option);
        await alert.present();
        return alert;
    }

    async showToast(toastOptions: ToastOptions) {
        const option = this.createOptions(toastOptions, 'toast');
        const toast = await this.toastController.create(option);
        toast.present();
    }

    createOptions(inputOption: AlertOptions | ToastOptions, type: 'alert' | 'toast') {
        this.loadDefaultOptions();

        let outputOptions: any;

        if (type === 'alert') {
            outputOptions = this.defaultAlertOptions;
        } else if (type === 'toast') {
            outputOptions = this.defaultToastOptions;
        }

        const inputEntries = Object.entries(inputOption);

        inputEntries.forEach(value => {
            outputOptions[value[0]] = value[1];
        });

        Object.keys(outputOptions).forEach(k => {
            if (outputOptions[k] === undefined) {
                delete outputOptions[k];
            }
        });

        return outputOptions;
    }

    loadDefaultOptions() {
        this.defaultAlertOptions = {
            header: undefined,
            subHeader: undefined,
            message: undefined,
            cssClass: undefined,
            inputs: undefined,
            buttons: undefined,
            backdropDismiss: true,
            translucent: false,
            animated: true,
            mode: undefined,
            keyboardClose: true,
            id: undefined,
            enterAnimation: undefined,
            leaveAnimation: undefined,
        };

        this.defaultToastOptions = {
            header: undefined,
            message: undefined,
            cssClass: undefined,
            duration: 3500,
            buttons: undefined,
            position: 'top',
            translucent: false,
            animated: true,
            color: 'dark',
            mode: undefined,
            keyboardClose: undefined,
            id: undefined,
            enterAnimation: undefined,
            leaveAnimation: undefined,
        };
    }

}
