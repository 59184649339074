import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular/standalone';
import { Observable, catchError, firstValueFrom, from, map, of, switchMap } from 'rxjs';
import { SessionQuery } from 'src/app/state/session/session.query';
import { SessionService } from '../state/session/session.service';
import { sessionStore } from '../state/session/session.store';
import { RequestProvider } from '../services/request/request';
import { HttpErrorResponse } from '@angular/common/http';
import { Device, DeviceId } from '@capacitor/device';
import { setProps } from '@ngneat/elf';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {

    constructor(private navCtrl: NavController,
        private sessionQuery: SessionQuery,
        private sessionService: SessionService,
        public request: RequestProvider) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

        const token = route.params.token;

        if (token && route.url[0].path === "booking-detail") {
            return this.loginWithToken(token).pipe(
                map(jwt => {
                    if (jwt) {
                        this.sessionService.update('jwt', jwt);
                        this.sessionService.setTokenPayload(String(jwt));
                        sessionStore.update(setProps({ token: String(jwt) }));
                        return true;
                    } else {
                        this.sessionService.logout();
                        this.navCtrl.navigateRoot('/login');
                        return false;
                    }
                })
            );
        } else if (this.sessionQuery.isLoggedIn() || token) {

            return true;
        } else {
            this.navCtrl.navigateRoot('/login');
            return false;
        }
    }

    loginWithToken(token: string) {
        return from(Device.getId()).pipe(
            switchMap((value: DeviceId) => this.request.sendPost({ api: 'users', mode: 'loginWithShareToken', token, 'deviceId': value.identifier }))
        );
    }
}
