import { inject, Injectable } from '@angular/core';
import { FirebaseRemoteConfig } from '@capacitor-firebase/remote-config';
import { LiveTrackingService } from 'src/app/state/live-tracking/live-tracking.service';

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {

  private liveTrackingService = inject(LiveTrackingService);

  async fetchRemoteConfig() {
    await FirebaseRemoteConfig.fetchAndActivate();
  }

  async addRemoteConfigUpdateListener() {
    await FirebaseRemoteConfig.addConfigUpdateListener(async (event, error) => {
      if (!error) {
        await this.fetchRemoteConfig(); // Fetch and update the store when there's a config change
        await this.liveTrackingService.setLiveTrackingValues('liveTrackingValues', 'liveTrackingValues');
        await this.liveTrackingService.setLiveTrackingValues('liveTrackingLogList', 'loglist');
      } else {
        console.error('Error fetching remote config update:', error);
      }
    });
  }
}
