import { UntypedFormGroup } from '@angular/forms';

export class IncidentDetails {

  private _reaktion: string;
  public get reaktion(): string {
    return this._reaktion;
  }

  private _info: any;
  public get info(): any {
    return this._info;
  }
  public set info(v: any) {
    this._info = v;
  }

  private _rekla_text: string;
  public get rekla_text(): string {
    return this._rekla_text;
  }

  public grund: string[];

  private _fahrer_ankunft: string;
  public get fahrer_ankunft(): string {
    return this._fahrer_ankunft;
  }

  private _fahrer_abholort_verlassen: string;
  public get fahrer_abholort_verlassen(): string {
    return this._fahrer_abholort_verlassen;
  }

  private _kunde_ankunft: string;
  public get kunde_ankunft(): string {
    return this._kunde_ankunft;
  }

  private _fahrer_ort: string;
  public get fahrer_ort(): string {
    return this._fahrer_ort;
  }

  private _fahrer_anruf: boolean;
  public get fahrer_anruf(): boolean {
    return this._fahrer_anruf;
  }
  public set fahrer_anruf(v: boolean) {
    this._fahrer_anruf = v;
  }

  private _fahrer_anruf_details_text: string;
  public get fahrer_anruf_details_text(): string {
    return this._fahrer_anruf_details_text;
  }

  private _fahrer_anruf_agentur: boolean;
  public get fahrer_anruf_agentur(): boolean {
    return this._fahrer_anruf_agentur;
  }
  public set fahrer_anruf_agentur(v: boolean) {
    this._fahrer_anruf_agentur = v;
  }

  private _fahrer_anruf_details_text_agentur: string;
  public get fahrer_anruf_details_text_agentur(): string {
    return this._fahrer_anruf_details_text_agentur;
  }

  private _kunde_reaktion: boolean;
  public get kunde_reaktion(): boolean {
    return this._kunde_reaktion;
  }
  public set kunde_reaktion(v: boolean) {
    this._kunde_reaktion = v;
  }

  private _kunde_reaktion_details_text: string;
  public get kunde_reaktion_details_text(): string {
    return this._kunde_reaktion_details_text;
  }

  private _agentur_reaktion: boolean;
  public get agentur_reaktion(): boolean {
    return this._agentur_reaktion;
  }
  public set agentur_reaktion(v: boolean) {
    this._agentur_reaktion = v;
  }

  private _agentur_reaktion_details_text: string;
  public get agentur_reaktion_details_text(): string {
    return this._agentur_reaktion_details_text;
  }

  private _tdl_reaktion_stellungnahme: string;
  public get tdl_reaktion_stellungnahme(): string {
    return this._tdl_reaktion_stellungnahme;
  }

  private _parkTicket: string;
  public get parkTicket(): string {
    return this._parkTicket;
  }
  public set parkTicket(v: string) {
    this._parkTicket = v;
  }

  private _gps: string;
  public get gps(): string {
    return this._gps;
  }
  public set gps(v: string) {
    this._gps = v;
  }

  private _abholort: string;
  public get abholort(): string {
    return this._abholort;
  }
  public set abholort(v: string) {
    this._abholort = v;
  }

  private _beleg: string;
  public get beleg(): string {
    return this._beleg;
  }
  public set beleg(v: string) {
    this._beleg = v;
  }

  private _anrufHistorie: string;
  public get anrufHistorie(): string {
    return this._anrufHistorie;
  }
  public set anrufHistorie(v: string) {
    this._anrufHistorie = v;
  }

  private _anrufHistorieAgentur: string;
  public get anrufHistorieAgentur(): string {
    return this._anrufHistorieAgentur;
  }
  public set anrufHistorieAgentur(v: string) {
    this._anrufHistorieAgentur = v;
  }

  private _sonstiges: string;
  public get sonstiges(): string {
    return this._sonstiges;
  }
  public set sonstiges(v: string) {
    this._sonstiges = v;
  }

  constructor(detailObject) {

    this._reaktion = detailObject['reaktion'];
    this._info = detailObject['info'];
    this._rekla_text = detailObject['rekla_text'];
    this.grund = detailObject['grund'];
    this._fahrer_ankunft = detailObject['fahrer_ankunft'];
    this._fahrer_abholort_verlassen = detailObject['fahrer_abholort_verlassen'];
    this._kunde_ankunft = detailObject['kunde_ankunft'];
    this._fahrer_ort = detailObject['fahrer_ort'];
    this._fahrer_anruf = detailObject['fahrer_anruf'] === 'yes' ? true : false;
    this._fahrer_anruf_details_text = detailObject['fahrer_anruf_details_text'];
    this._fahrer_anruf_agentur = detailObject['fahrer_anruf_agentur'] === 'yes' ? true : false;
    this._fahrer_anruf_details_text_agentur = detailObject['fahrer_anruf_details_text_agentur'];
    this._kunde_reaktion = detailObject['kunde_reaktion'] === 'yes' ? true : false;
    this._kunde_reaktion_details_text = detailObject['kunde_reaktion_details_text'];
    this._agentur_reaktion = detailObject['agentur_reaktion'] === 'yes' ? true : false;
    this._agentur_reaktion_details_text = detailObject['agentur_reaktion_details_text'];
    this._tdl_reaktion_stellungnahme = detailObject['tdl_reaktion_stellungnahme'];
  }

  updateDetails(form: UntypedFormGroup) {

    this._fahrer_ankunft = form['locationTime'];
    this._fahrer_abholort_verlassen = form['locationLeavingTime'];
    this._kunde_ankunft = form['arrivalTime'];
    this._fahrer_ort = form['where'];
    this._fahrer_anruf = form['driverCalledToggle'];
    this._fahrer_anruf_details_text = form['driverCalled'];
    this._fahrer_anruf_agentur = form['driverCalledAgenturToggle'];
    this._fahrer_anruf_details_text_agentur = form['driverCalledAgentur'];
    this._kunde_reaktion = form['customerCalledToggle'];
    this._kunde_reaktion_details_text = form['customerCalled'];
    this._agentur_reaktion = form['agencyCalledToggle'];
    this._agentur_reaktion_details_text = form['agencyCalled'];
    this._tdl_reaktion_stellungnahme = form['otherNotes'];
  }

  loadDetails(data: IncidentDetails) {

    this._fahrer_ankunft = data._fahrer_ankunft;
    this._fahrer_abholort_verlassen = data._fahrer_abholort_verlassen;
    this._kunde_ankunft = data._kunde_ankunft;
    this._fahrer_ort = data._fahrer_ort;
    this._fahrer_anruf = data._fahrer_anruf;
    this._fahrer_anruf_details_text = data._fahrer_anruf_details_text;
    this._fahrer_anruf_agentur = data._fahrer_anruf_agentur;
    this._fahrer_anruf_details_text_agentur = data._fahrer_anruf_details_text_agentur;
    this._kunde_reaktion = data._kunde_reaktion;
    this._kunde_reaktion_details_text = data._kunde_reaktion_details_text;
    this._agentur_reaktion = data._agentur_reaktion;
    this._agentur_reaktion_details_text = data._agentur_reaktion_details_text;
    this._parkTicket = data._parkTicket;
    this._gps = data._gps;
    this._abholort = data._abholort;
    this._beleg = data._beleg;
    this._anrufHistorie = data._anrufHistorie;
    this._tdl_reaktion_stellungnahme = data._tdl_reaktion_stellungnahme;
  }

  loadImages(images) {

    this._abholort = images.bild_abholort;
    this._anrufHistorie = images.anrufhistorie;
    this._anrufHistorieAgentur = images.anrufhistorieagentur;
    this._beleg = images.bild_beleg;
    this._gps = images.gps_daten;
    this._parkTicket = images.parkticket;
    this._sonstiges = images.bild_sonstiges;
  }

  incidentSubmitted() {

    this._reaktion = '1';
  }
}
