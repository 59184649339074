import { IncidentDetails } from './incidentDetails';

export class Incident {

  private _id: string;
  public get id(): string {
    return this._id;
  }

  private _type: string;
  public get type(): string {
    return this._type;
  }

  private _buch_code: string;
  public get buch_code(): string {
    return this._buch_code;
  }

  public incidentDetails: IncidentDetails;

  constructor(jsonObject) {

    this._id = jsonObject['id'];
    this._type = jsonObject['type'];
    this._buch_code = jsonObject['buch_code'];
    this.incidentDetails = new IncidentDetails(jsonObject['details']);
  }
}
