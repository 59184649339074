import { Injectable } from '@angular/core';
import { DeliveredNotificationSchema, LocalNotifications } from '@capacitor/local-notifications';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(private translate: TranslateService) {}

    /**
     * Registriert die Aktions-Typen für Benachrichtigungen.
     */
    registerActionTypes(translations: { [key: string]: string }): Promise<void> {
        const options = {
            types: [
                {
                    id: 'notification-actions-pickup',
                    actions: [
                        {
                            id: 'kunde-aufgenommen',
                            title: translations["notification_pickup_reached_button1"],
                            inputButtonTitle: translations["notification_pickup_reached_button1"],
                        },
                        {
                            id: 'no-show',
                            title: translations["notification_pickup_reached_button2"],
                            inputButtonTitle: translations["notification_pickup_reached_button2"],
                            destructive: true,
                            foreground: true
                        }
                    ]
                },
                {
                    id: 'notification-actions-dropoff',
                    actions: [
                        {
                            id: 'kunde-abgesetzt',
                            title: translations["notification_pickup_reached_button3"],
                            inputButtonTitle: translations["notification_pickup_reached_button3"],
                        }
                    ]
                }
            ]
        };

        return LocalNotifications.registerActionTypes(options).then(() => {
            console.log('Notification actions registered successfully.');
        }).catch((error) => {
            console.error('Failed to register notification actions:', error);
            throw error;
        });
    }

    /**
     * Sendet eine lokale Benachrichtigung.
     */
    scheduleNotification(notification: { id: number, title: string, body: string, actionTypeId: string, smallIcon: string }): Promise<void> {
        return LocalNotifications.schedule({
            notifications: [notification]
        }).then(() => {
            console.log(`${notification.title} notification scheduled.`);
        }).catch((error) => {
            console.error(`Failed to schedule ${notification.title} notification:`, error);
            throw error;
        });
    }

    /**
     * Entfernt eine lokale Benachrichtigung anhand der ID.
     */
    removeNotification(notificationId: DeliveredNotificationSchema): Promise<void> {
        return LocalNotifications.removeDeliveredNotifications({
            notifications: [notificationId]
        }).then(() => {
            console.log(`Notification with ID ${notificationId} removed.`);
        }).catch((error) => {
            console.error(`Failed to remove notification with ID ${notificationId}:`, error);
            throw error;
        });
    }

    /**
     * Holt alle ausgelieferten Benachrichtigungen.
     */
    getDeliveredNotifications(): Promise<{ notifications: any[] }> {
        return LocalNotifications.getDeliveredNotifications();
    }

    /**
     * Fügt einen Listener für Benachrichtigungsaktionen hinzu.
     */
    addListener(eventName: 'localNotificationActionPerformed', callback: (notificationAction: any) => void): void {
        LocalNotifications.addListener(eventName, callback);
    }
}