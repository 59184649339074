export class BookingDiversion {
    public cateringIncluded: boolean;
    public flightNumber: string;
    public originalAirportCode: string;
    public passengers: string;
    public route: string;
    public via: string;
    public vehicles: { vehicle: string, pax: number, details: string }[];
    public pickupPoint: string;
    public dropoffPoint: string;

    constructor(json) {        
        this.cateringIncluded = Boolean(json["catering_included"]);
        this.flightNumber = json["flight_number"];
        this.originalAirportCode = json["original_airport_code"];
        this.passengers = json["passengers"];
        this.route = json["route"];
        this.via = json["via"];
        this.vehicles = json["vehicles"];
        this.pickupPoint = json["pickup_point"];
        this.dropoffPoint = json["dropoff_point"];
    }
}