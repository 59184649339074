export const LIVE_TRACKING_DEFAULTS = {
    ADDRESS_RADIUS_IN_METERS: 50,
    AIRPORT_RADIUS_IN_METERS: 150,
    LEAVING_RADIUS_IN_METERS: 100,
    KILL_SWITCH_DISTANCE_IN_METERS: 2000,
    LOCATION_REACHED_TIMING_FACTOR: 0.3,
    PICKUP_LEAVING_THRESHOLD_PERCENTAGE: 0.15,
    DROPOFF_LEAVING_THRESHOLD_PERCENTAGE: 0.15,
    DROPOFF_KILLSWITCH_THRESHOLD_PERCENTAGE: 0.30,
    ACTIVATION_PERCENTAGE: 0.3,
    LIVE_TRACKING_ENABLED: true,
    PICKUP_LOCATION_DETECTION_ENABLED: true,
    DROPOFF_LOCATION_DETECTION_ENABLED: true,
    PICKUP_LEFT_DETECTION_ENABLED: true,
    DROPOFF_LEFT_DETECTION_ENABLED: true,
    STOP_AFTER_ELAPSED_MINUTES: 360,
    HEADING_TO_PICKUP_TIME_THRESHOLD: 2,
    HEADING_TO_PICKUP_ACTIVATION_DISTANCE_THRESHOLD: 0.1,
    ACTIVITY_RECOGNITION_STOP_TIMEOUT: 30
};

export const CONSTANTS = {
    EARTH_RADIUS_IN_KM: 6371
};