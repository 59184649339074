import { Component, NgZone, OnInit } from '@angular/core';
import { NavController, Platform } from '@ionic/angular/standalone';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { PreferencesProvider } from './services/preferences/preferences';
import { environment, firebaseConfig } from '../environments/environment';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { SessionService } from './state/session/session.service';
import { SessionQuery } from './state/session/session.query';
import { User } from './state/user/user.model';
import { Observable } from 'rxjs';
import { UserQuery } from './state/user/user.query';
import { AuthenticationProvider } from './services/authentication/authentication';
import { userStore } from './state/user/user.store';
import { TokenPayload } from './interfaces/tokenPayload.interface';
import { Keyboard, KeyboardStyle } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Browser } from '@capacitor/browser';
import { ConnectionStatus, Network } from '@capacitor/network';
import * as Sentry from "@sentry/capacitor/";
import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import { NgIf, AsyncPipe } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { HasPermissionDirective } from './directive/has-permission.directive';
import { addIcons } from "ionicons";
import { personCircleOutline, book, people, time, flash, helpCircle, cog, logOut } from "ionicons/icons";
import { IonRouterLink, IonApp, IonSplitPane, IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonMenuToggle, IonItem, IonIcon, IonLabel, IonBadge, IonRouterOutlet } from "@ionic/angular/standalone";
import { App, AppInfo } from '@capacitor/app';
import { isAfter, addHours } from 'date-fns';
import { LiveTrackingService } from './state/live-tracking/live-tracking.service';
import { RemoteConfigService } from './services/remote-config/remote-config.service';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { FcmService } from './services/fcm/fcm.service';
import { addEntities } from '@ngneat/elf-entities';
import { setProp } from '@ngneat/elf';
import { LucideAngularModule, Book, FileText, Users, Clock, AlertTriangle, HelpCircle, SignpostBig, DollarSign, Settings, LogOut, CircleUser } from 'lucide-angular';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    standalone: true,
    imports: [RouterLink, RouterLinkActive, NgIf, AsyncPipe, TranslateModule, HasPermissionDirective, IonRouterLink, IonApp, IonSplitPane, IonMenu, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonMenuToggle, IonItem, IonIcon, IonLabel, IonBadge, IonRouterOutlet, LucideAngularModule]
})
export class AppComponent implements OnInit {

    public user = '';
    public appVersion = environment.version;
    public buildNumber = '0';
    public userName: string;
    public userType: string;
    private language = "de";
    public environment = environment;

    public userName$: Observable<any>;
    public networkStatus$ = this.sessionQuery.networkStatus$;
    public complaintCount$ = this.sessionQuery.complaintcount$;
    public isContactEmailDead$ = this.sessionQuery.isContactEmailDead$;

    readonly Book = Book;
    readonly FileText = FileText;
    readonly Users = Users;
    readonly Clock = Clock;
    readonly AlertTriangle = AlertTriangle;
    readonly HelpCircle = HelpCircle;
    readonly SignpostBig = SignpostBig;
    readonly DollarSign = DollarSign;
    readonly Settings = Settings;
    readonly LogOut = LogOut;
    readonly CircleUser = CircleUser;

    constructor(
        private platform: Platform,
        private translate: TranslateService,
        public preferences: PreferencesProvider,
        private navCtrl: NavController,
        private dialog: DialogService,
        private sessionService: SessionService,
        public sessionQuery: SessionQuery,
        public userQuery: UserQuery,
        private authenticationProvider: AuthenticationProvider,
        private ngZone: NgZone,
        private liveTracking: LiveTrackingService,
        private remoteConfigService: RemoteConfigService,
        private fcm: FcmService
    ) {
        platform.ready().then(() => {
            // Okay, so the platform is ready and our plugins are available.
            // Here you can do any higher level native things you might need.

            this.setTheme();

            if (platform.is('capacitor')) {
                Network.addListener('networkStatusChange', (status: ConnectionStatus) => {
                    this.ngZone.run(() => {
                        let isOffline = !status.connected;
                        this.preferences.setValue('isOffline', undefined, false);
                        this.preferences.setValue('isOffline', String(isOffline), false);
                    });
                });
            }

            const ionApp = document.getElementsByTagName('ion-app')[0];

            platform.resume.subscribe(() => {
                this.preferences.getValue('lastLogin', false).subscribe(async nextLoginTimestamp => {
                    if (isAfter(new Date(), nextLoginTimestamp)) {
                        ionApp.classList.add('blurPage');
                        const isVerified = await this.authenticationProvider.verifyUser();

                        if (isVerified) {

                            this.preferences.setValue('lastLogin', addHours(new Date(), 8).toString(), false);
                            ionApp.classList.remove('blurPage');
                        } else {
                            this.navCtrl.navigateRoot('/logout');
                            ionApp.classList.remove('blurPage');
                        }
                    }
                });

                if (this.sessionQuery.isLoggedIn()) {
                    this.sessionService.getComplaintsCounter();
                    this.fcm.clearAllMessages();
                }
            });

            this.preferences.getValue('showPwaInfo', false).subscribe(async (showPwaInfo: boolean) => {
                if (showPwaInfo !== false) {
                    if (this.platform.is('pwa') && (this.platform.is('android') || this.platform.is('ios'))) {
                        let appStoreUrl = "";

                        if (this.platform.is('ios')) {
                            appStoreUrl = "itms-apps://itunes.apple.com/app/apple-store/id1462622160?mt=8";
                        }
                        if (this.platform.is('android')) {
                            appStoreUrl = "https://play.google.com/store/apps/details?id=com.worldtransfer.contractors";
                        }

                        this.translate.get('app_global.pwa_info_message').subscribe(async pwaInfoMessage => {
                            let cancelString = this.translate.instant('app_global.cancel');
                            let toStoreString = this.translate.instant('app_global.to_store_text');

                            let pwaInfoAlert = await this.dialog.showAlert({
                                message: pwaInfoMessage,
                                buttons: [
                                    cancelString,
                                    {
                                        text: toStoreString,
                                        role: 'confirm',
                                        handler: () => { window.open(appStoreUrl); }
                                    }]
                            });

                            pwaInfoAlert.onDidDismiss().then(() => {
                                this.preferences.setValue('showPwaInfo', false, false);
                            });
                        });
                    }
                }
            });
        });

        this.init();
        addIcons({ personCircleOutline, book, people, time, flash, helpCircle, cog, logOut });
    }

    async ngOnInit() {
        await this.sessionService.getSettings();
        this.language = this.sessionQuery.getSettingsValue('language');

        await this.remoteConfigService.fetchRemoteConfig();

        if ((this.platform.is('ios') || this.platform.is('android')) && Capacitor.isNativePlatform()) {
            await this.remoteConfigService.addRemoteConfigUpdateListener();
        }
    }

    initTranslate() {
        this.preferences.getValue('language', false).subscribe(value => {

            const browserLang = this.translate.getBrowserLang().toLowerCase();

            this.translate.setDefaultLang('en');

            if (value === 'de' || value === 'en') {

                this.translate.use(value);
                this.preferences.setValue('language', value, false);
            } else if (browserLang.includes('de')) {

                this.translate.use('de');
                this.preferences.setValue('language', 'de', false);
            } else {

                this.translate.use('en');
                this.preferences.setValue('language', 'en', false);
            }
        });
    }

    init() {
        this.initTranslate();

        this.userQuery.$usersLoaded.subscribe(async (usersLoaded) => {
            if (usersLoaded && !this.userQuery.isUserInit()) {
                const sessionUserId: number = this.sessionQuery.getUserId();
                const tokenPayload: TokenPayload = this.sessionQuery.getTokenPayload();

                if (+sessionUserId === 0) {
                    const user: User = {
                        id: tokenPayload.user_id,
                        gtuNr: String(tokenPayload.gtu),
                        name: 'World Transfer',
                        username: 'World Transfer',
                        user_level: String(tokenPayload.userLevel),
                        active: true,
                        forname: 'World',
                        surname: 'Transfer',
                        mobile: '00000'
                    }

                    userStore.update(addEntities(user));
                }

                const sessionUser: User = this.userQuery.getUserById(sessionUserId);

                if (sessionUser.forname && sessionUser.surname) {
                    this.userName = sessionUser.forname + ' ' + sessionUser.surname;
                } else if (sessionUser.username) {
                    this.userName = sessionUser.username.split('@')[0];
                }

                if (sessionUser?.user_level) {
                    this.userType = sessionUser.user_level;
                }

                Sentry.configureScope((scope) => {
                    scope.setUser(
                        { id: String(tokenPayload.gtu), username: sessionUser.username }
                    );
                });

                await FirebaseAnalytics.setUserId({
                    userId: String(tokenPayload.gtu) + '#' + String(tokenPayload.user_id)
                });

                this.sessionService.setLoginActivityEntry();
                userStore.update(setProp('isUserInit', true));   
                
                this.liveTracking.initGeolocation();
            }
        });

        if (!Capacitor.isNativePlatform()) {
            initializeApp(firebaseConfig);
        } else {
            if (!environment.production) {
                App.getInfo().then((value: AppInfo) => {
                    this.buildNumber = value.build;
                });   
            }
        }

        FirebaseAnalytics.setEnabled({ enabled: true })
    }

    setTheme() {

        const setStatusBarStyleLight = async () => {
            await StatusBar.setStyle({ style: Style.Light });
        };

        const setStatusBarStyleDark = async () => {
            await StatusBar.setStyle({ style: Style.Dark });
        };

        const setKeyboardStyleLight = async () => {
            await Keyboard.setStyle({ style: KeyboardStyle.Light });
        };

        const setKeyboardStyleDark = async () => {
            await Keyboard.setStyle({ style: KeyboardStyle.Dark });
        };

        const setStatusBarBackgroundLight = async () => {
            await StatusBar.setBackgroundColor({ color: '#FFFFFF' });
        };

        const setStatusBarBackgroundDark = async () => {
            await StatusBar.setBackgroundColor({ color: '#000000' });
        };

        const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)');

        this.preferences.getValue('theme', false).subscribe(theme => {
            if (theme) {
                theme === 'dark' ? document.body.classList.toggle('dark', true) : document.body.classList.toggle('dark', false);

                if (this.platform.is('hybrid')) {
                    if (this.platform.is('android')) {
                        theme === 'dark' ? setStatusBarBackgroundDark() : setStatusBarBackgroundLight();
                    }

                    theme === 'dark' ? setStatusBarStyleDark() : setStatusBarStyleLight();

                    if (this.platform.is('ios')) {
                        theme === 'dark' ? setKeyboardStyleDark() : setKeyboardStyleLight();
                    }
                }
            } else {
                isDarkMode.matches ? document.body.classList.toggle('dark', true) : document.body.classList.toggle('dark', false);

                if (this.platform.is('hybrid')) {
                    if (this.platform.is('android')) {
                        isDarkMode.matches ? setStatusBarBackgroundDark() : setStatusBarBackgroundLight();
                    }

                    isDarkMode.matches ? setStatusBarStyleDark() : setStatusBarStyleLight();

                    if (this.platform.is('ios')) {
                        isDarkMode.matches ? setKeyboardStyleDark() : setKeyboardStyleLight();
                    }
                }

                this.preferences.setValue('theme', isDarkMode.matches ? 'dark' : 'light', false);
            }
        });
    }

    openDocumentation() {
        let docsUrl = environment.docsUrl + '/index.html';

        if (this.language === 'en') {
            docsUrl = environment.docsUrl + '/en/index.html';
        }

        if (this.platform.is("ios") && this.platform.is("mobileweb")) {
            window.open(docsUrl);
        } else {
            Browser.open({
                url: docsUrl
            });
        }
    }

    handleProfileClick(event: Event) {
        const el = (event.currentTarget as HTMLElement);
        el.classList.add('clicked');
        setTimeout(() => el.classList.remove('clicked'), 200);
      }
}
