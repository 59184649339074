import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { forkJoin as observableForkJoin, Subject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SessionQuery } from 'src/app/state/session/session.query';
import { User } from 'src/app/state/user/user.model';
import { UserQuery } from 'src/app/state/user/user.query';
import { Booking, BookingProgress } from '../../models/booking/booking';
import { RequestProvider } from '../request/request';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { PermissionStatus, Position } from '@capacitor/geolocation';
import { BookingEvents } from 'src/app/interfaces/bookingEvents.enum';
import { GeolocationService } from '../geolocation/geolocation.service';
import { parse, isValid, format, parseISO, isBefore, isSameDay, addDays } from 'date-fns';
import { WtGeolocation } from 'src/app/state/live-tracking/live-tracking.service';

@Injectable({
    providedIn: 'root'
})
export class BookingProvider {

    public bookings: any = {
        unconfirmed: [],
        confirmed: []
    };
    public dateFrom = undefined;
    public dateTo = undefined;
    public activeBookingSegment: string;

    public isBookingConfirmed$ = new Subject<{ isBookingConfirmed: boolean, bookingCode: string, isDiversion: boolean }>();
    public bookingDeclined$ = new Subject<string>();
    public bookingCount$ = new Subject<number>();
    public hasBookingRendered$ = new Subject<boolean>();

    constructor(public request: RequestProvider,
        private platform: Platform,
        private sessionQuery: SessionQuery,
        private userQuery: UserQuery,
        public geolocation: GeolocationService
    ) { }

    public loadBookings(filter: any, search: string, activeSegment: string) {

        this.bookings.unconfirmed = [];
        this.bookings.confirmed = [];
        this.activeBookingSegment = activeSegment;

        return this.getBookings(filter, search, activeSegment).pipe(
            map((bookings: Booking[]) => {

                switch (activeSegment) {
                    case 'unconfirmed':
                        this.bookings.unconfirmed = bookings;
                        break;
                    case 'confirmed':
                        this.bookings.confirmed = bookings;
                        break;
                }

                this.dateFrom = this.parseDate(bookings['meta'].from_date);
                this.dateTo = this.parseDate(bookings['meta'].to_date);

                if (activeSegment === 'confirmed' && this.platform.is('capacitor')) {
                    this.cacheConfirmedBookings();
                }

                return bookings;
            })
        );
    }

    public getBookings(filter: any, search: string, activeSegment: string) {

        if (filter !== undefined && activeSegment === 'unconfirmed') {

            if (filter.filter.unconfirmed.open === true && filter.filter.unconfirmed.declined === true) {

                return observableForkJoin([
                    this.sendBookingRequest(this.createParameterString(search, filter, 'declined')),
                    this.sendBookingRequest(this.createParameterString(search, filter, 'unconfirmed'))
                ]).pipe(
                    map(data => {
                        const returnArray = data[0].concat(data[1]);
                        returnArray.sort((booking1: Booking, booking2: Booking) => booking1.vorlaufzeit - booking2.vorlaufzeit);
                        returnArray['meta'] = data[1]['meta'];

                        return returnArray;
                    })
                );
            } else if (filter.filter.unconfirmed.declined === false) {

                return this.sendBookingRequest(this.createParameterString(search, filter, 'unconfirmed'));
            } else if (filter.filter.unconfirmed.open === false) {

                return this.sendBookingRequest(this.createParameterString(search, filter, 'declined'));
            }

        } else if (filter !== undefined && activeSegment === 'confirmed') {

            return this.sendBookingRequest(this.createParameterString(search, filter, 'confirmed'));
        }

        return this.sendBookingRequest(this.createParameterString(search, filter, activeSegment));
    }

    public loadSingleBooking(buchCode: string, bookingSegment: string = 'unconfirmed') {

        return this.request.sendPost({
            api: 'bookings',
            mode: 'select_' + bookingSegment,
            buchCode
        }).pipe(
            map(data => {

                if (data['body'][0]) {
                    return new Booking(data['body'][0]);
                }
            })
        );
    }

    private sendBookingRequest(params: Object) {

        return this.request.sendPost(params).pipe(
            map(data => {
                const bookingsArray: Booking[] = [];
                for (let index = 0; index < data['body']['length']; index++) {
                    bookingsArray.push(new Booking(data['body'][index]));
                }

                if (data['meta'].from_date !== '' && data['meta'].to_date !== '') {

                    bookingsArray['meta'] = data['meta'];
                }

                return bookingsArray;
            })
        );

    }

    private createParameterString(search, filter: any, mode: string): Object {

        const parameters = {
            api: 'bookings',
            mode: 'select_' + mode
        };

        if (search) {

            if (search.searchValue !== '') {
                parameters['search_query'] = search.searchValue;
                delete parameters.mode;

                return parameters;
            }
        }
        if (filter) {
            if (filter.sort) {
                parameters['sort_by'] = filter.sort;
            }

            parameters['date_from'] = format(parseISO(filter.filter.date.from), 'dd.MM.yyyy');
            parameters['date_to'] = format(parseISO(filter.filter.date.to), 'dd.MM.yyyy');
        }

        return parameters;
    }

    public acceptBookingAsOperator(bookingId: string, driverId: string, bookingType: string) {

        return this.request.sendPost({
            api: 'bookings',
            mode: 'acceptance',
            record_id: bookingId,
            driver_id: driverId,
            type: bookingType
        }).pipe(
            map(data => data)
        );
    }

    public acceptBookingAsDriver(bookingId: string, userId: number, activeSegment: string, remove: boolean) {

        return this.request.sendPost({
            api: 'bookings',
            mode: 'acceptance_driver',
            driver_id: userId,
            record_id: bookingId
        }).pipe(
            map(data => {

                if (remove) {
                    this.removeBooking(bookingId, activeSegment);
                }
                return data;
            })
        );
    }

    public declineBookingAsOperator(bookingId: string, reason: string, priceProposal: string) {

        return this.request.sendPost({
            api: 'bookings',
            mode: 'decline',
            record_id: bookingId,
            reason_for_decline: reason,
            price_proposal: priceProposal
        }).pipe(
            map(data => data)
        );
    }

    public declineBookingAsDriver(bookingId: string, driverId: number) {

        return this.request.sendPost({
            api: 'bookings',
            mode: 'decline',
            driver_id: driverId,
            record_id: bookingId
        }).pipe(
            map(data => data)
        );
    }

    public removeBooking(bookingId: string, activeSegment: string) {

        this.bookings[activeSegment] = this.bookings[activeSegment].filter((booking: Booking) => booking.buch_id !== bookingId);
    }

    public changeDriver(bookingId: string, driverId: string, activeSegment: string) {

        return this.request.sendPost({
            api: 'bookings',
            mode: driverId === '-1' ? 'unassign_driver' : 'assign_driver',
            driver_id: driverId,
            record_id: bookingId
        }).pipe(
            switchMap(() => {
                const booking = this.getBooking(bookingId, activeSegment);

                if (booking) {

                    if (driverId === '-1') {
                        booking.fahrer_id = undefined;
                        booking.fahrer_status = undefined;

                        return of(null);
                    } else {

                        return of(booking);
                    }
                }

                throw new Error('#E01');
            }),
            switchMap((booking: Booking) => {

                if (booking) {
                    const user: User = this.userQuery.getUserById(Number(driverId));

                    booking.fahrer_id = driverId;
                    booking.fahrer_status = undefined;

                    if (user.user_level === 'operator') {
                        booking.fahrer_status = 'accept'
                        return of('operator');
                    }
                    if (user.user_level === 'driver') {
                        return of('driver');
                    }
                } else if (booking === null) {
                    return of(null);
                }
                else {

                    return of(false);
                }
            }),
            switchMap(accept => {

                if (accept === 'operator') {
                    return this.acceptBookingAsDriver(bookingId, Number(driverId), activeSegment, false).pipe(
                        map(() => {
                            return true;
                        })
                    );
                } else if (accept === 'driver') {
                    return of(true);
                } else if (accept === null) {
                    return of(true);
                } else {

                    return of(false);
                }
            })
        );
    }

    public sendBookingConfirmation(bookingId: string) {

        return this.request.sendPost({
            api: 'bookings',
            mode: 'confirmation',
            record_id: bookingId
        }).pipe(
            map(data => data)
        );
    }

    public updateTransferTime(transferTime: string, bookingId: string) {

        return this.request.sendPost({
            api: 'bookings',
            mode: 'updateTransferTime',
            record_id: bookingId,
            time: transferTime
        }).pipe(
            map(data => data)
        );
    }

    public changeNotification(changeId, bookingId) {

        return this.request.sendPost({
            api: 'bookings',
            mode: 'change_notification',
            change_id: changeId,
            record_id: bookingId
        }).pipe(
            map(data => data)
        );
    }

    public async cacheConfirmedBookings() {

        const bookings = this.getBookingsForCaching();
        const bookingsAsJson = JSON.stringify(bookings);

        const result = await Filesystem.writeFile({
            directory: Directory.Data,
            path: '/Cache/ConfirmedBookings.json',
            data: bookingsAsJson,
            recursive: true,
            encoding: Encoding.UTF8
        });
    }

    public getBookingsForCaching() {

        const cacheBookings = [];

        this.bookings.confirmed.forEach((booking: Booking) => {

            const transferDate = parse(booking.transferdatum, 'yyyy-MM-dd', new Date());
            const tomorrow = addDays(new Date(), 1);

            if (isBefore(transferDate, tomorrow) || isSameDay(transferDate, tomorrow)) {
                cacheBookings.push(booking);
            }
        });

        return cacheBookings;
    }

    async loadCachedBookings() {

        const file = await this.readCachedBookingsFile();

        if (file !== undefined) {
            if (file.data) {
                this.bookings.unconfirmed = [];
                this.bookings.confirmed = [];

                if (typeof file.data === "string") {
                    const parsedBookings = JSON.parse(file.data);

                    for (const booking of parsedBookings) {
                        let newBooking = Object.assign(new Booking(), booking);
                        this.bookings.confirmed = [... this.bookings.confirmed, newBooking];
                    }
                }
            }
        }

        return this.bookings.confirmed;
    }

    async readCachedBookingsFile() {
        try {
            return await Filesystem.readFile({
                directory: Directory.Data,
                path: '/Cache/ConfirmedBookings.json',
                encoding: Encoding.UTF8
            });
        } catch (error) {
            return undefined;
        }
    }

    removeFirstCharRecursive(element) {

        Object.keys(element).forEach(key => {

            if (element[key] instanceof Object) {

                element[key] = this.removeFirstCharRecursive(element[key]);
            }

            if (key.charAt(0) === '_') {
                element[key.substring(1)] = element[key];
                delete element[key];
            }
        });

        return element;
    }

    async deleteCachedBookings() {
        try {
            return await Filesystem.deleteFile({
                directory: Directory.Data,
                path: "/Cache/ConfirmedBookings.json"
            });
        } catch (error) {
            return undefined;
        }
    }

    resetBookingsByDriver(driverId: number) {

        this.loadBookings(null, null, 'confirmed').subscribe(() => {

            this.bookings.confirmed.forEach((element: Booking) => {

                if (element.fahrer_id === driverId) {

                    this.changeDriver(element.buch_id, '0', 'confirmed').subscribe();
                }
            });
        });
    }

    public filterBooking(searchTerm: string, bookingSegment: string) {
        if (!this.bookings[bookingSegment]) {
            this.bookings[bookingSegment] = [];
        }

        if (searchTerm && searchTerm.trim() !== '') {

            this.bookings[bookingSegment] = this.bookings[bookingSegment].filter(booking => {

                if (booking?.buch_code) {

                    if (booking.buch_code.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
                        return true;
                    }
                }

                if (booking?.tour_id) {

                    if (booking.tour_id.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
                        return true;
                    }
                }

                if (booking?.details?.fahrgastname) {
                    if (booking.details.fahrgastname.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1) {
                        return true;
                    }
                }

                return false;
            });
        }

        return this.bookings[bookingSegment].length > 0;
    }

    public createExtendedCsvData() {
        this.createCsvData(this.bookings['confirmed']);
    }

    public createCsvData(bookings: Booking[] | Booking[][]) {

        const language = this.sessionQuery.getSettingsValue('language');
        let headerRow: string[];

        if (language === 'de') {
            headerRow = [
                'buch_code', 'tour_id', 'voucher_id', 'datum', 'abholzeit', 'von_kind', 'von_bezeichnung', 'von_strasse', 'von_plz', 'von_ort', 'pickup',
                'nach_kind', 'nach_bezeichnung', 'nach_strasse', 'nach_plz', 'nach_ort', 'dropoff', 'flug_nr', 'abflughafen', 'abflugzeit',
                'zielflughafen', 'ankunftzeit', 'personenanzahl', 'kundenname', 'mobil', 'preis', 'zahlart', 'storniert', 'transferart', 'fahrzeugklasse', 'fahrzeugtyp', 'fahrer', 'bemerkung'];
        } else {
            headerRow = [
                'booking_code', 'tour_id', 'voucher_id', 'date', 'pickup_time', 'from_kind', 'from_description', 'from_street', 'from_zip', 'from_city', 'pickup',
                'to_kind', 'to_description', 'to_street', 'to_zip', 'to_city', 'dropoff', 'flight_nr', 'departure_airport', 'departure_time',
                'destination_airport', 'arrival_time', 'number_of_persons', 'customer_name', 'mobile', 'price', 'payment_method', 'canceled', 'transfer_type', 'vehicle_class', 'vehicle_type', 'driver', 'note'];
        }

        const bookingArray: any[] = [];

        bookings.forEach(booking => {

            if (Array.isArray(booking)) {
                booking.forEach(tourBooking => {
                    bookingArray.push(this.createCSVBookingElement(tourBooking, language));
                });
            } else {
                bookingArray.push(this.createCSVBookingElement(booking, language));
            }
        });

        let csvString = '';
        csvString = headerRow.join(';') + '\n';

        bookingArray.forEach(booking => {

            csvString += '"' + booking.join('";"') + '"\n';
        });

        const blob = new Blob(['\ufeff' + csvString], { type: 'text/csv;charset=utf-8;' });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = new Date().toLocaleString() + '.csv';

        setTimeout(() => {

            a.click();
        }, 1000);
    }

    createCSVBookingElement(booking: Booking, language: string) {

        let transferArt = '';
        let wagentyp = booking.wagentyp;

        if (booking.tour_id !== '') {
            transferArt = "Tour";
        } else {
            transferArt = booking.zub_option === '1' ? 'Standard' : 'Einzeltransfer';
        }

        if (language !== 'de') {
            if (booking.wagentyp === 'PKW') {
                wagentyp = 'CAR';
            }
        }

        const driver = this.userQuery.getUserById(booking.fahrer_id);
        let driverName = "";

        if (driver && driver.forname && driver.surname) {
            driverName = driver.forname + ' ' + driver.surname;
        } else if (driver) {
            driverName = driver.username;
        }

        return [
            booking.buch_code, booking.tour_id, booking.voucher_id, booking.transferdatum_local, booking.abholzeit_local, booking.details.transferart_start,
            booking.von_bezeichnung === '' ? booking.von : booking.von_bezeichnung, booking.von_strasse, booking.von_plz, booking.von_ort, booking.pickup_punkt,
            booking.details.transferart_ziel, booking.nach_bezeichnung === '' ? booking.nach : booking.nach_bezeichnung, booking.nach_strasse, booking.nach_plz, booking.nach_ort,
            booking.dropoff_punkt, booking.details.flugnr, booking.details.flugsrc, booking.details.abflugzeit === 'Invalid Date' ? "" : booking.details.abflugzeit, booking.details.flugdest,
            booking.details.ankunftszeit === 'Invalid Date' ? "" : booking.details.ankunftszeit, booking.anzahl_pers, booking.details.fahrgastname, booking.details.fahrgastname_telefon,
            booking.preis.toString().replace('.', ','), booking.zahlart, booking.storniert !== '0000-00-00 00:00:00' ? booking.storniert : '',
            transferArt, booking.wagenklasse, wagentyp, driverName, booking.details.bemerkungen
        ];
    }

    getBooking(bookingId: string, bookingSegment: string) {

        return this.bookings[bookingSegment].find((booking: Booking) => (booking.buch_code === bookingId) || (booking.buch_id === bookingId));
    }

    getBookingsWithPackageId(packageId: string) {

        const bookings: Booking[] = [];

        this.bookings[this.activeBookingSegment].filter(booking => {

            if (booking.package_id === packageId) {
                bookings.push(booking);
            }
        });

        return bookings;
    }

    getBookingsWithTourId(tourId: string) {

        const bookings: Booking[] = [];

        this.bookings[this.activeBookingSegment].filter(booking => {

            if (booking.tour_id === tourId) {
                bookings.push(booking);
            }
        });

        return bookings;
    }

    isBookingConfirmed(bookingCode: string) {

        return this.loadSingleBooking(bookingCode, 'confirmed').pipe(
            map((booking: Booking) => {

                if (!!booking) {
                    if (booking.voucher_id !== '0' && String(booking.unternehmer_id) === String(this.sessionQuery.getTdlNr()).replace('GTU-', '')) {

                        return booking;
                    } else {

                        return null;
                    }
                }
            })
        );
    }

    updateBooking(oldBooking: Booking, newBooking: Booking, bookingSegment: string) {

        return new Observable(observer => {

            const bookingIndex = this.bookings[bookingSegment].indexOf(oldBooking);

            if (bookingIndex > -1) {

                this.bookings[bookingSegment][bookingIndex] = newBooking;
                observer.next(newBooking);
                observer.complete();
            }
        });
    }

    updateBookingValues(keyValuesToUpdate: { 'key': string, 'value': any }[], bookingSegment: string, buchCode: string) {

        let bookingFound = false;

        const bookingIndex = this.bookings[bookingSegment].findIndex((booking: Booking) => booking.buch_code === buchCode);

        if (bookingIndex > -1) {

            keyValuesToUpdate.some(pair => {

                this.bookings[bookingSegment][bookingIndex][pair.key] = pair.value;

                bookingFound = true;
                return true;
            });
        }

        if (bookingFound) {

            return true;
        }
    }

    updateProgress(bookingProgress: BookingProgress, buchCode: string, position: WtGeolocation, source: string) {
        let body = {
            api: 'bookings',
            mode: 'update_progress',
            buchCode,
            progress: (bookingProgress + 1),
            source
        };

        if (position && position.coordinates) {
            body['latitude'] = position?.coordinates.latitude;
            body['longitude'] = position?.coordinates.longitude;
            body['accuracy'] = position?.coordinates.accuracy;
            body['timestamp'] = position?.timestamp;
            body['permissionType'] = '';

            if (position?.permission === 'reduced') {
                body['permissionType'] = 'coarse';
            } else {
                body['permissionType'] = 'precise';
            }
        }

        return this.request.sendPost(body).pipe(
            map(data => {

                if (data['meta'].status === 1) {

                    const updateValueArray = [{
                        key: 'progress',
                        value: bookingProgress + 1
                    }];
                    this.updateBookingValues(updateValueArray, 'confirmed', buchCode);

                    return true;
                }

                return false;
            })
        );
    }

    async sendEvent(eventName: BookingEvents, buchCode: string) {
        let position: { coordinates: Position, permission: PermissionStatus };

        if (this.platform.is('ios') || this.platform.is('android')) {
            position = await this.geolocation.getCurrentGeolocation();
        }

        let body = {
            api: 'bookings',
            mode: 'send_event',
            buchCode,
            eventName
        };

        if (position && position.coordinates) {
            body['latitude'] = position?.coordinates.coords.latitude;
            body['longitude'] = position?.coordinates.coords.longitude;
            body['accuracy'] = position?.coordinates.coords.accuracy;
            body['permissionType'] = '';

            if (position?.permission.coarseLocation === 'granted') {
                body['permissionType'] = 'coarse';
            }
            if (position?.permission.location === 'granted') {
                body['permissionType'] = 'precise';
            }
        }

        this.request.sendPost(body).subscribe();
    }

    searchBookingByCode(bookingCode: string) {

        return this.request.sendPost({
            api: 'bookings',
            search_query: bookingCode
        }).pipe(
            map(data => {

                const bookingData = data['body'].find((booking: Booking) => booking.buch_code === bookingCode);

                if (bookingData) {

                    const booking = new Booking(bookingData);

                    if (booking) {
                        return booking;
                    }
                }

                return null;
            })
        );
    }

    getBookingCount(bookingSegment: string) {
        let count = 0;

        if (bookingSegment === 'unconfirmed') {
            count = this.bookings.unconfirmed.length;
        } else {
            this.bookings[bookingSegment].forEach((booking: Booking) => {
                if (booking.roletype_ident === 1) {
                    count++;
                }
            });
        }

        return count;
    }

    getEventData(bookingCode: string) {
        return this.request.sendPost({
            api: 'bookings',
            mode: 'get_event_data',
            buchCode: bookingCode
        }).pipe(
            map(events => {
                return events['body'];
            })
        );
    }

    getShareToken(bookingCode: string) {
        return this.request.sendPost({
            api: 'bookings',
            mode: 'get_share_token',
            bookingCode
        }).pipe(
            map((value) => {
                if (value && value['body']['token']) {
                    return value['body']['token'];
                } else {
                    return null;
                }
            })
        );
    }

    getShareLink(bookingCode: string) {
        return this.request.sendPost({
            api: 'bookings',
            mode: 'get_share_link',
            bookingCode
        }).pipe(
            map((value) => {
                if (value) {
                    return value['body']['shareLink']
                }
            })
        );
    }

    parseDate(dateString: string | null): Date {      
        if (!dateString) {
            return new Date();
        } else {
            const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
            return isValid(parsedDate) ? parsedDate : new Date();
        }
    }
}
