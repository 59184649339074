import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { driverEventsStore } from './driver-events.store';
import { Booking } from 'src/app/models/booking/booking';
import { WtGeolocation } from '../live-tracking/live-tracking.service';
import { BookingProvider } from 'src/app/services/booking/booking';
import { mergeMap, tap } from 'rxjs/operators';
import { forkJoin, from, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { DriverEventsQuery } from './driver-events.query';
import { setProps } from '@ngneat/elf';

@Injectable({ providedIn: 'root' })
export class DriverEventsService {
  bookingProv = inject(BookingProvider);
  translate = inject(TranslateService);
  driverEventsQuery = inject(DriverEventsQuery);

  constructor(private http: HttpClient) { }

  updateDriverEventProgress(
    booking: Booking,
    position: WtGeolocation,
    source: string
  ): Observable<any> {
    if (booking.tour_id) {
      const bookingList = this.bookingProv.getBookingsWithTourId(booking.tour_id);
      const observables: Observable<any>[] = [];

      for (const bookingItem of bookingList) {
        observables.push(
          this.bookingProv.updateProgress(bookingItem.progress, bookingItem.buch_code, position, source)
        );
      }

      return forkJoin(observables).pipe(
        tap(() => {
          driverEventsStore.update(setProps(((state) => ({
            driverEventState: state.driverEventState + 1
          }))));
        }),
        mergeMap(() =>
          from(
            FirebaseAnalytics.logEvent({
              name: 'driver_event_changed',
              params: {
                bookingCode: booking?.buch_code,
                event: this.driverEventsQuery.getDriverEventState(),
                source,
              },
            })
          )
        )
      );
    } else {
      return this.bookingProv
        .updateProgress(booking.progress, booking.buch_code, position, source)
        .pipe(
          tap(() => {
            driverEventsStore.update(setProps((state) => ({
              driverEventState: state.driverEventState + 1,
            })));
          }),
          mergeMap(() =>
            from(
              FirebaseAnalytics.logEvent({
                name: 'driver_event_changed',
                params: {
                  bookingCode: booking?.buch_code,
                  event: this.driverEventsQuery.getDriverEventState(),
                  source,
                },
              })
            )
          )
        );
    }
  }

  initDriverEvent(actualState: number) {
    driverEventsStore.update(setProps({ driverEventState: actualState }));
    this.loadLanguageStrings();
  }

  loadLanguageStrings() {
    this.translate
      .get([
        'booking.details.progress_1',
        'booking.details.progress_2',
        'booking.details.progress_3',
        'booking.details.progress_4',
        'booking.details.call_customer',
        'booking.details.call_agency',
      ])
      .subscribe(values => {
        const driverEventStrings = {
          ...values,
          'booking.details.progress_3.1': 'NoShow',
          'booking.details.noshow': 'NoShow',
          'booking.details.call_customer': values['booking.details.call_customer'],
          'booking.details.call_agency': values['booking.details.call_agency'],
        };

        driverEventsStore.update(setProps({ driverEventStrings }));
      });
  }
}