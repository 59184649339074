import { Injectable } from '@angular/core';
import BackgroundGeolocation, { State, Location, LocationAuthorizationRequest } from '@transistorsoft/capacitor-background-geolocation';
import { Device } from '@capacitor/device';

@Injectable({
    providedIn: 'root'
})
export class GeolocationService {
    constructor() {}

    /**
     * Initialisiert die Geolocation-Konfiguration.
     */
    async getGeolocationConfig(token: string, translations: { [key: string]: string }, liveTrackingApiUrl: string): Promise<any> {
        const deviceId = (await Device.getId()).identifier;

        return {
            url: `${liveTrackingApiUrl}saveLiveTrackingData`,
            authorization: {
                strategy: 'JWT',
                accessToken: token
            },
            notification: {
                title: 'World Transfer - Live Tracking',
                text: translations["android_notification_text"],
                smallIcon: 'drawable/push_icon_name',
                channelName: 'Live Tracking'
            },
            activityType: BackgroundGeolocation.ACTIVITY_TYPE_AUTOMOTIVE_NAVIGATION,
            headers: {
                'Client_Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
                'Device_Id': deviceId
            },
            disableLocationAuthorizationAlert: true,
            locationAuthorizationRequest: 'Any' as LocationAuthorizationRequest,
            stopAfterElapsedMinutes: 0,
            stopTimeout: 5
        };
    }

    /**
     * Startet die Geolocation-Überwachung.
     */
    async ready(config: any): Promise<State> {
        return BackgroundGeolocation.ready(config);
    }

    /**
     * Startet die Geolocation.
     */
    start(): Promise<State> {
        return BackgroundGeolocation.start();
    }

    /**
     * Stoppt die Geolocation.
     */
    stop(): Promise<State> {
        return BackgroundGeolocation.stop();
    }

    /**
     * Setzt die Konfiguration für BackgroundGeolocation.
     */
    setConfig(config: any): Promise<State> {
        return BackgroundGeolocation.setConfig(config);
    }

    /**
     * Gibt den aktuellen Provider-Status zurück.
     */
    getProviderState(): Promise<{ status: number }> {
        return BackgroundGeolocation.getProviderState();
    }

    /**
     * Fügt einen Listener für Standortaktualisierungen hinzu.
     */
    onLocation(callback: (location: Location) => void): void {
        BackgroundGeolocation.onLocation(callback);
    }

    /**
     * Fügt einen Listener für Änderungen der Aktivierung hinzu.
     */
    onEnabledChange(callback: (enabled: boolean) => void): void {
        BackgroundGeolocation.onEnabledChange(callback);
    }

    /**
     * Fügt einen Listener für Konnektivitätsänderungen hinzu.
     */
    onConnectivityChange(callback: (event: { connected: boolean }) => void): void {
        BackgroundGeolocation.onConnectivityChange(callback);
    }

    sendLog(liveTrackingApiUrl: string) {
        BackgroundGeolocation.logger.uploadLog(`${liveTrackingApiUrl}logs`).then((success) => {
            BackgroundGeolocation.logger.destroyLog();
        }).catch((error) => {
        });
    }
}