import { inject, Injectable } from '@angular/core';
import BackgroundGeolocation, { Location } from '@transistorsoft/capacitor-background-geolocation';
import { SessionQuery } from 'src/app/state/session/session.query';
import { Capacitor } from '@capacitor/core';
import { LiveTrackingQuery } from './live-tracking.query';
import { Booking, BookingProgress } from 'src/app/models/booking/booking';
import { WT_Permission } from '@german-transfer/wt-permission';
import { take } from 'rxjs';
import { DriverEventsService } from '../driver-events/driver-events.service';
import { LocalNotifications } from '@capacitor/local-notifications';
import { FirebaseRemoteConfig } from '@capacitor-firebase/remote-config';
import { Router } from '@angular/router';
import { LocationUtils, ZoneGroup } from './location.utils';
import { LIVE_TRACKING_DEFAULTS } from './tracking.config';
import { DriverEventsQuery } from '../driver-events/driver-events.query';
import { TranslateService } from '@ngx-translate/core';
import { environment } from './../../../environments/environment';
import { differenceInMilliseconds, format } from 'date-fns';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { ZoneService } from './live-tracking-zone.service';
import { GeolocationService } from './live-tracking-geolocation.service';
import { NotificationService } from './live-tracking-notification.service';
import { liveTrackingStore } from './live-tracking.store';
import { setProp, setProps } from '@ngneat/elf';

@Injectable({ providedIn: 'root' })
export class LiveTrackingService {

  private sessionQuery = inject(SessionQuery);
  private liveTrackingQuery = inject(LiveTrackingQuery);
  private driveEventService = inject(DriverEventsService);
  private driverEventQuery = inject(DriverEventsQuery);
  private router = inject(Router);
  private translate = inject(TranslateService);
  private zoneService = inject(ZoneService);
  private geolocationService = inject(GeolocationService);
  private notificationService = inject(NotificationService);

  booking: Booking | null = null;

  activeNotificationId: number | null = null;
  pickupMinDistance: number = Infinity;
  dropoffMinDistance: number = Infinity;
  initialPositionToPickupDistance: number | null = null;
  pickupToDropoffDistance: number | null = null;
  pickupMinDistancePosition: { lat: number; lon: number } | null = null;

  liveTrackingValues = { ...LIVE_TRACKING_DEFAULTS };
  currentLocation: Location | undefined;

  pickupLocationReachedTimer: ReturnType<typeof setTimeout> | null = null;
  dropoffLocationReachedTimer: ReturnType<typeof setTimeout> | null = null;

  translations: { [key: string]: string } = {};

  private pickupZones: ZoneGroup | null = null;
  private dropoffZones: ZoneGroup | null = null;
  private logList: number[] = [];
  private startTimestamp = null;

  /**
   * Initialisiert die Geolocation und setzt die notwendigen Listener.
   */
  async initGeolocation() {
    this.translate.get('live_tracking').pipe(take(1)).subscribe(res => this.translations = res);
    await this.setLiveTrackingValues('liveTrackingValues', 'liveTrackingValues');
    await this.setLiveTrackingValues('liveTrackingLogList', 'logList');

    if (Capacitor.isNativePlatform() && this.liveTrackingValues.LIVE_TRACKING_ENABLED) {
      const config = await this.geolocationService.getGeolocationConfig(
        this.sessionQuery.getToken(),
        this.translations,
        environment.liveTrackingApiUrl
      );

      try {
        const state = await this.geolocationService.ready(config);
        liveTrackingStore.update(setProps({ isGeolocationReady: true, isLiveTrackingActive: state.enabled }));
      } catch (error) {
        console.error('BackgroundGeolocation initialization failed:', error);
        return;
      }

      this.geolocationService.onConnectivityChange(async (event) => {
        await FirebaseAnalytics.logEvent({
          name: 'live_tracking_connection_change',
          params: { connected: event.connected },
        });
        BackgroundGeolocation.logger.debug('Connection available: ' + event.connected);
      });

      this.geolocationService.onLocation((location: Location) => {
        this.handleLocationUpdate(location);
      });

      this.geolocationService.onEnabledChange(async (enabled: boolean) => {
        await this.handleEnabledChange(enabled);
      });

      this.notificationService.registerActionTypes(this.translations)
        .then(() => {
          this.notificationService.addListener('localNotificationActionPerformed', this.handleNotificationAction.bind(this));
        })
        .catch(error => {
          console.error('Notification actions registration failed:', error);
        });

      this.geolocationService.setConfig({ stopAfterElapsedMinutes: this.liveTrackingValues.STOP_AFTER_ELAPSED_MINUTES });
      this.geolocationService.setConfig({ stopTimeout: this.liveTrackingValues.ACTIVITY_RECOGNITION_STOP_TIMEOUT });
    }
  }

  /**
   * Behandelt Änderungen der Aktivierung des Live-Trackings.
   */
  private async handleEnabledChange(enabled: boolean) {
    if (!enabled) {
      this.resetLiveTrackingState();
      await FirebaseAnalytics.logEvent({
        name: 'live_tracking_stopped',
        params: { bookingCode: this.booking?.buch_code },
      });

      if (this.logList.includes(+this.sessionQuery.getTdlNr())) {
        this.geolocationService.sendLog(environment.liveTrackingApiUrl);
      }
    } else {
      await FirebaseAnalytics.logEvent({
        name: 'live_tracking_started',
        params: { bookingCode: this.booking?.buch_code },
      });
      this.startTimestamp = new Date();
    }
    liveTrackingStore.update(setProps({ isLiveTrackingActive: enabled }));
  }

  /**
   * Setzt die Live-Tracking-Werte aus der Remote-Konfiguration.
   */
  public async setLiveTrackingValues(key: string, variableName: string) {
    try {
      const { value } = await FirebaseRemoteConfig.getString({ key });

      if (value) {
        this[variableName] = JSON.parse(value);
      }
    } catch (error) {
      console.error('Failed to fetch live tracking values:', error);
    }
  }

  /**
   * Startet das Live-Tracking für eine bestimmte Buchung.
   */
  async start(booking: Booking) {
    this.booking = booking;
    BackgroundGeolocation.logger.debug('Booking:' + JSON.stringify(booking));

    await FirebaseAnalytics.logEvent({
      name: 'live_tracking_start',
      params: { method: booking.buch_code },
    });

    if (!this.liveTrackingValues.LIVE_TRACKING_ENABLED || (await (BackgroundGeolocation.getProviderState())).status <= 3) {
      return;
    }

    this.setLogging();

    liveTrackingStore.update(setProp('activeBookingCode', booking.buch_code));
    BackgroundGeolocation.logger.info('Buchungscode: ' + booking.buch_code);

    if (this.liveTrackingQuery.isReady() && !this.liveTrackingQuery.isLiveTrackingActive()) {
      try {
        if (booking.progress >= BookingProgress.arrivedAtPickupPoint) {
          liveTrackingStore.update(setProp('hasReachedPickupLocation', true));
        }

        await this.geolocationService.setConfig({
          params: {
            'api': 'bookings',
            'mode': 'live_tracking',
            'bookingCode': booking.buch_code,
            'tourId': booking.tour_id,
            'isHeadingToPickup': this.liveTrackingQuery.hasReachedPickupLocation()
          },
          notification: {
            title: `Live Tracking -> ${booking.buch_code}`
          }
        });

        await this.geolocationService.start();
        await BackgroundGeolocation.changePace(true);
      } catch (error) {
        console.error('Failed to start BackgroundGeolocation:', error);
      }
    }
  }

  /**
   * Stoppt das Live-Tracking.
   */
  async stop(source: string) {
    await FirebaseAnalytics.logEvent({
      name: 'live_tracking_stop',
      params: { bookingCode: this.booking?.buch_code, source },
    });

    BackgroundGeolocation.logger.debug('Stop-Source: ' + source);

    if (this.liveTrackingQuery.isReady() && this.liveTrackingQuery.isLiveTrackingActive()) {
      try {
        await this.geolocationService.stop();
        await this.removeLocalNotifications();
      } catch (error) {
        console.error('Failed to stop BackgroundGeolocation:', error);
      }
    }
  }

  /**
   * Behandelt Standortaktualisierungen.
   */
  async handleLocationUpdate(location: Location) {
    try {
      if (location?.coords) {
        this.currentLocation = location;
        this.processPickupZones(location);
        this.processDropoffZones(location);
        this.handleDriverEventState(location);
      }
    } catch (error) {
      console.error('Failed to handle location update:', error);
    }
  }

  /**
   * Verarbeitet Pickup-Zonen.
   */
  private processPickupZones(location: Location) {
    if (this.pickupZones && this.liveTrackingValues.PICKUP_LOCATION_DETECTION_ENABLED) {
      if (LocationUtils.isWithinBoundingBox(location.coords, this.pickupZones.boundingBox)) {
        const minDistancePickup = Math.min(...this.pickupZones.zones.map(zone => this.calculateDistance(location, zone)));
        // console.log('Min Distance to Pickup:', minDistancePickup);

        const closestPickupZone = this.pickupZones.zones.find(zone => this.calculateDistance(location, zone) === minDistancePickup);
        const pickupRadius = closestPickupZone ? closestPickupZone.radius : this.liveTrackingValues.ADDRESS_RADIUS_IN_METERS;
        this.manageTimer(minDistancePickup, 'pickup', pickupRadius, 'pickupLocationReachedTimer', location);
      } else {
        this.clearTimer('pickupLocationReachedTimer');
        // console.log('Pickup Timer gestoppt, außerhalb der Bounding Box.');
      }
    }
  }

  /**
   * Verarbeitet Dropoff-Zonen.
   */
  private processDropoffZones(location: Location) {
    if (this.dropoffZones && this.liveTrackingValues.DROPOFF_LOCATION_DETECTION_ENABLED) {
      if (LocationUtils.isWithinBoundingBox(location.coords, this.dropoffZones.boundingBox)) {
        liveTrackingStore.update(setProp('isInDropoffZone', true));
        const minDistanceDropoff = Math.min(...this.dropoffZones.zones.map(zone => this.calculateDistance(location, zone)));
        const closestDropoffZone = this.dropoffZones.zones.find(zone => this.calculateDistance(location, zone) === minDistanceDropoff);
        const dropoffRadius = closestDropoffZone ? closestDropoffZone.radius : this.liveTrackingValues.ADDRESS_RADIUS_IN_METERS;
        this.manageTimer(minDistanceDropoff, 'dropoff', dropoffRadius, 'dropoffLocationReachedTimer', location);
      } else {
        liveTrackingStore.update(setProp('isInDropoffZone', false));
        this.clearTimer('dropoffLocationReachedTimer');
      }
    }
  }

  /**
   * Verarbeitet den aktuellen Fahrerstatus im Zusammenhang mit dem Standort.
   */
  private async handleDriverEventState(location: Location) {
    if (!this.liveTrackingQuery.hasLeftPickupLocation()) {
      const distanceToPickupLocation = LocationUtils.haversineDistance(
        location.coords.latitude,
        location.coords.longitude,
        this.pickupZones?.boundingBox.center.lat || 0,
        this.pickupZones?.boundingBox.center.lon || 0
      );
      if (distanceToPickupLocation < this.pickupMinDistance) {
        this.pickupMinDistance = distanceToPickupLocation;
        this.pickupMinDistancePosition = {
          lat: location.coords.latitude,
          lon: location.coords.longitude
        };
      }
      if (this.initialPositionToPickupDistance === null) {
        this.initialPositionToPickupDistance = distanceToPickupLocation;
      }

      if (this.liveTrackingValues.PICKUP_LEFT_DETECTION_ENABLED) {
        const activationThreshold = (this.getActivationThreshold(this.initialPositionToPickupDistance || 0, this.pickupZones?.boundingBox.center || { lat: 0, lon: 0 }) + this.pickupZones?.boundingBox.maxDistanceFromCenter);
        if (distanceToPickupLocation <= activationThreshold) {
          liveTrackingStore.update(setProp('pickupLeftDetectionActivated', true));
        }

        if (this.liveTrackingQuery.pickupLeftDetectionActivated()) {
          await this.manageLocationLeftDetection(
            distanceToPickupLocation,
            'pickup',
            () => this.liveTrackingQuery.hasLeftPickupLocation(),
            () => this.pickupMinDistance
          );
        }
      }

      if (!this.liveTrackingQuery.isHeadingToPickup()) {        
        BackgroundGeolocation.logger.debug('StartTimestamp: ' + this.startTimestamp + ', DistanceToPickup: ' + distanceToPickupLocation + ', DistanceFromStartToPickup: ' + this.initialPositionToPickupDistance);
        this.checkDriverHeadingToPickup(this.startTimestamp, distanceToPickupLocation, this.initialPositionToPickupDistance);
      }
    }

    if (this.liveTrackingValues.DROPOFF_LEFT_DETECTION_ENABLED && this.driverEventQuery.getDriverEventState() > BookingProgress.customerCollected) {
      const distanceToDropoffLocation = LocationUtils.haversineDistance(
        location.coords.latitude,
        location.coords.longitude,
        this.dropoffZones?.boundingBox.center.lat || 0,
        this.dropoffZones?.boundingBox.center.lon || 0
      );
      this.pickupToDropoffDistance = LocationUtils.haversineDistance(
        this.pickupMinDistancePosition?.lat || 0,
        this.pickupMinDistancePosition?.lon || 0,
        this.dropoffZones?.boundingBox.center.lat || 0,
        this.dropoffZones?.boundingBox.center.lon || 0
      );
      if (distanceToDropoffLocation < this.dropoffMinDistance) {
        this.dropoffMinDistance = distanceToDropoffLocation;
      }

      const activationThreshold = (this.getActivationThreshold(this.pickupToDropoffDistance || 0, this.dropoffZones?.boundingBox.center || { lat: 0, lon: 0 }) + this.dropoffZones?.boundingBox.maxDistanceFromCenter);
      if (distanceToDropoffLocation <= activationThreshold) {
        liveTrackingStore.update(setProp('dropoffLeftDetectionActivated', true));
      }

      if (this.liveTrackingQuery.dropoffLeftDetectionActivated()) {
        await this.manageLocationLeftDetection(
          distanceToDropoffLocation,
          'dropoff',
          () => this.liveTrackingQuery.hasLeftDropoffLocation(),
          () => this.dropoffMinDistance
        );
      }
    }
  }

  /**
   * Berechnet die Distanz zu einer Zone.
   */
  private calculateDistance(location: Location, zone: { lat: number; lon: number }): number {
    return LocationUtils.haversineDistance(location.coords.latitude, location.coords.longitude, zone.lat, zone.lon);
  }

  /**
   * Verwalten von Timern für Standortbenachrichtigungen.
   */
  private manageTimer(distance: number, locationType: 'pickup' | 'dropoff', radiusInMeters: number, timerName: 'pickupLocationReachedTimer' | 'dropoffLocationReachedTimer', location: Location) {
    if (distance <= radiusInMeters && !this.liveTrackingQuery[`hasReached${this.capitalize(locationType)}Location`]?.()) {
      if (!this[timerName]) {
        this[timerName] = setTimeout(() => {
          this.locationReachedActions(location, locationType);
        }, this.liveTrackingValues.LOCATION_REACHED_TIMING_FACTOR * radiusInMeters * 1000);
      }
    } else if (this[timerName] && distance > radiusInMeters && !this.liveTrackingQuery[`hasReached${this.capitalize(locationType)}Location`]?.()) {
      clearTimeout(this[timerName]);
      this[timerName] = null;
    }
  }

  /**
   * Verwaltet die Erkennung, wenn der Fahrer eine Zone verlässt.
   */
  private async manageLocationLeftDetection(
    currentDistance: number,
    locationType: 'pickup' | 'dropoff',
    hasLeftLocationMethod: () => boolean,
    getMinDistanceMethod: () => number
  ) {
    const hasLeftLocationKey = locationType === 'pickup' ? 'hasLeftPickupLocation' : 'hasLeftDropoffLocation';
    const minDistance = getMinDistanceMethod();
    const leavingThreshold = minDistance + this.calculateLeavingThreshold(locationType) + (locationType === 'pickup' ? this.pickupZones?.boundingBox.maxDistanceFromCenter : this.dropoffZones?.boundingBox.maxDistanceFromCenter);
    const killSwitchThreshold = minDistance + this.calculateKillSwitchThreshold(locationType) + (locationType === 'pickup' ? this.pickupZones?.boundingBox.maxDistanceFromCenter : this.dropoffZones?.boundingBox.maxDistanceFromCenter);

    if (!hasLeftLocationMethod()) {
      if (currentDistance > leavingThreshold) {
        liveTrackingStore.update(setProp(`${hasLeftLocationKey}`, true));

        if (locationType === 'pickup' && this.driverEventQuery.getDriverEventState() < (BookingProgress.customerCollected + 1) || locationType === 'dropoff' && this.driverEventQuery.getDriverEventState() < (BookingProgress.customerDropped + 1)) {
          await this.notifyMovedAwayFromLocation(locationType);
        }

        FirebaseAnalytics.logEvent({
          name: `live_tracking_left_${locationType}`,
          params: {
            bookingCode: this.booking?.buch_code
          }
        });
        BackgroundGeolocation.logger.debug('Location left ' + locationType);
      }
    } else if (currentDistance <= leavingThreshold) {
      liveTrackingStore.update(setProp(`${hasLeftLocationKey}`, false));
    }

    if (locationType === 'dropoff' && this.liveTrackingQuery.isLiveTrackingActive()) {
      if (currentDistance > killSwitchThreshold) {
        if (!this.liveTrackingQuery.killSwitchActivated()) {
          liveTrackingStore.update(setProp('killSwitchActivated', true));
          this.stop('Killswitch');
        }
      }
    }
  }

  /**
   * Berechnet den Aktivierungs-Schwellenwert.
   */
  private getActivationThreshold(totalDistance: number, center: { lat: number; lon: number }): number {
    const activationPercentage = this.liveTrackingValues.ACTIVATION_PERCENTAGE;
    return totalDistance * activationPercentage;
  }

  /**
   * Berechnet den Schwellenwert für das Verlassen einer Zone.
   */
  private calculateLeavingThreshold(locationType: 'pickup' | 'dropoff'): number {
    const percentage =
      locationType === 'pickup'
        ? this.liveTrackingValues.PICKUP_LEAVING_THRESHOLD_PERCENTAGE
        : this.liveTrackingValues.DROPOFF_LEAVING_THRESHOLD_PERCENTAGE;
    const initialDistance =
      locationType === 'pickup' ? this.initialPositionToPickupDistance : this.pickupToDropoffDistance;
    return initialDistance !== null ? initialDistance * percentage : 0;
  }

  /**
   * Berechnet den Schwellenwert für den Kill-Switch.
   */
  private calculateKillSwitchThreshold(locationType: 'pickup' | 'dropoff'): number {
    const percentage =
      locationType === 'pickup'
        ? 0
        : this.liveTrackingValues.DROPOFF_KILLSWITCH_THRESHOLD_PERCENTAGE;
    const initialDistance =
      locationType === 'pickup' ? this.initialPositionToPickupDistance : this.pickupToDropoffDistance;
    return initialDistance !== null ? initialDistance * percentage : 0;
  }

  /**
   * Setzt die Standorte für Pickup und Dropoff.
   */
  setLocations(pickupLocation: { lat: number, lon: number, isAirport: boolean, iata: string } | null, dropoffLocation: { lat: number, lon: number, isAirport: boolean, iata: string } | null) {
    if (pickupLocation) {
      this.zoneService.loadZones('pickup', pickupLocation, pickupLocation.isAirport ? this.liveTrackingValues.AIRPORT_RADIUS_IN_METERS : this.liveTrackingValues.ADDRESS_RADIUS_IN_METERS)
        .pipe(take(1))
        .subscribe(zoneGroup => {
          this.pickupZones = zoneGroup;
          BackgroundGeolocation.logger.debug('Pickup:' + JSON.stringify(this.pickupZones));
        });
    }

    if (dropoffLocation) {
      this.zoneService.loadZones('dropoff', dropoffLocation, dropoffLocation.isAirport ? this.liveTrackingValues.AIRPORT_RADIUS_IN_METERS : this.liveTrackingValues.ADDRESS_RADIUS_IN_METERS)
        .pipe(take(1))
        .subscribe(zoneGroup => {
          this.dropoffZones = zoneGroup;
          BackgroundGeolocation.logger.debug('Dropoff:' + JSON.stringify(this.dropoffZones));
        });
    }

    liveTrackingStore.update(setProp('hasAutomaticEvents', true));
  }

  /**
   * Behandelt Aktionen, wenn eine Zone erreicht wird.
   */
  async locationReachedActions(location: Location, locationType: 'pickup' | 'dropoff') {
    try {
      if (this.booking) {
        if (locationType === 'pickup') {
          liveTrackingStore.update(setProp('hasReachedPickupLocation', true));
        }
        if (locationType === 'dropoff') {
          liveTrackingStore.update(setProp('hasReachedDropoffLocation', true));
        }

        if (locationType === 'pickup' || (locationType === 'dropoff' && this.driverEventQuery.getDriverEventState() > BookingProgress.customerCollected)) {
          const permission = await WT_Permission.checkPermissions();
          this.driveEventService.updateDriverEventProgress(this.booking, {
            coordinates: {
              latitude: this.currentLocation?.coords.latitude || 0,
              longitude: this.currentLocation?.coords.longitude || 0,
              accuracy: this.currentLocation?.coords.accuracy || 0
            },
            permission: permission.accuracy === 'reduced' ? 'reduced' : 'precise',
            timestamp: format(new Date(location.timestamp), 'yyyy-MM-dd HH:mm:ss')
          }, `live_tracking_${locationType}`).pipe(take(1)).subscribe({
            next: () => console.log('Driver event progress updated successfully.'),
            error: (error) => console.error('Failed to update driver event progress:', error),
            complete: async () => {
              if (locationType === 'dropoff') {
                await this.stop('Location reached dropoff')
              }
            }
          });

          FirebaseAnalytics.logEvent({
            name: `live_tracking_reached_${locationType}`,
            params: {
              bookingCode: this.booking?.buch_code
            }
          });
          BackgroundGeolocation.logger.debug('Location reached ' + locationType);
        } else {
          if (locationType === 'dropoff') {
            await this.stop('Location reached dropoff')
          }
        }
      } else {
        console.error('Booking information is missing for location reached actions.');
      }
    } catch (error) {
      console.error('Failed to handle location reached actions:', error);
    }
  }

  /**
   * Benachrichtigt den Fahrer, wenn er eine Zone verlassen hat.
   */
  async notifyMovedAwayFromLocation(locationType: 'pickup' | 'dropoff') {
    const notificationTitle = locationType === 'pickup' ? this.translations["notification_pickup_left_title"] : this.translations["notification_dropoff_left_title"];
    const notificationBody = locationType === 'pickup' ? this.translations["notification_pickup_left_body"] : this.translations["notification_dropoff_left_body"];

    try {
      await this.removeLocalNotifications();

      this.activeNotificationId = Number(Date.now().toString().slice(4));
      await this.notificationService.scheduleNotification({
        id: this.activeNotificationId,
        title: notificationTitle,
        body: notificationBody,
        actionTypeId: `notification-actions-${locationType}`,
        smallIcon: 'push_icon_name'
      });
      // console.log(`${notificationTitle} notification sent.`);
    } catch (error) {
      console.error(`Failed to send ${locationType} moved away notification:`, error);
    }
  }

  async removeLocalNotifications() {
    if (this.activeNotificationId) {
      const deliveredNotifications = await LocalNotifications.getDeliveredNotifications();

      if (deliveredNotifications) {
        const activeNotification = deliveredNotifications.notifications.find(n => n.id === this.activeNotificationId);

        if (activeNotification) {
          await this.notificationService.removeNotification(activeNotification);
        }
      }

      this.activeNotificationId = null;
    }
  }

  /**
   * Behandelt Aktionen, die durch Benachrichtigungen ausgelöst werden.
   */
  async handleNotificationAction(notificationAction: any) {
    const actionId = notificationAction.actionId;
    if (
      (actionId === 'kunde-aufgenommen' && this.driverEventQuery.getDriverEventState() <= BookingProgress.customerCollected) ||
      (actionId === 'kunde-abgesetzt' && this.driverEventQuery.getDriverEventState() <= BookingProgress.customerDropped)
    ) {
      if (this.currentLocation && this.booking) {
        try {
          const permission = await WT_Permission.checkPermissions();
          await this.driveEventService.updateDriverEventProgress(this.booking, {
            coordinates: {
              latitude: this.currentLocation.coords.latitude,
              longitude: this.currentLocation.coords.longitude,
              accuracy: this.currentLocation.coords.accuracy,
            },
            permission: permission.accuracy === 'reduced' ? 'reduced' : 'precise',
            timestamp: format(new Date(this.currentLocation.timestamp), 'yyyy-MM-dd HH:mm:ss')
          }, 'live_tracking_push').pipe(take(1)).toPromise();

          if (actionId === 'kunde-abgesetzt') {
            await this.stop('Location reached notification.');
          }
        } catch (error) {
          console.error('Failed to update driver event progress:', error);
        }
      } else {
        console.error('Current location or booking information is missing.');
      }
    } else if (actionId === 'no-show') {
      if (this.booking) {
        this.checkIfOnCorrectPage(this.booking.buch_code);
        liveTrackingStore.update(setProp('nowShowEventTriggered', true));
      } else {
        console.error('Booking information is missing for NoShow action.');
      }
    }
  }

  /**
   * Setzt die Position als erreicht.
   */
  setLocationReached(locationType: 'pickup' | 'dropoff'): void {
    if (locationType === 'pickup') {
      liveTrackingStore.update(setProp('hasReachedPickupLocation', true));
      this.clearTimers();
    }
    if (locationType === 'dropoff') {
      liveTrackingStore.update(setProp('hasReachedDropoffLocation', true));
      this.clearTimers();
    }
  }

  /**
   * Überprüft, ob der Fahrer sich auf der erwarteten Seite befindet.
   */
  private checkIfOnCorrectPage(bookingId: string): void {
    const expectedRoutePrefix = `/booking-detail/confirmed/${bookingId}`;
    if (!this.router.url.startsWith(expectedRoutePrefix)) {
      this.router.navigate([expectedRoutePrefix]);
    }
  }

  /**
   * Setzt alle Timer zurück.
   */
  private clearTimers() {
    this.clearTimer('pickupLocationReachedTimer');
    this.clearTimer('dropoffLocationReachedTimer');
  }

  /**
   * Entfernt einen spezifischen Timer.
   */
  private clearTimer(timerName: 'pickupLocationReachedTimer' | 'dropoffLocationReachedTimer') {
    if (this[timerName]) {
      clearTimeout(this[timerName]);
      this[timerName] = null;
    }
  }

  /**
   * Setzt den Status des Live-Trackings zurück.
   */
  private resetLiveTrackingState() {
    liveTrackingStore.update((state) => ({
      ...state,
      isLiveTrackingActive: false,
      activeBookingCode: undefined,
      hasReachedPickupLocation: false,
      hasReachedDropoffLocation: false,
      hasLeftPickupLocation: false,
      hasAutomaticEvents: false,
      killSwitchActivated: false,
      pickupLeftDetectionActivated: false,
      dropoffLeftDetectionActivated: false,
      isHeadingToPickup: false,
      isInDropoffZone: false
    }));
    this.clearTimers();
    this.activeNotificationId = null;
    this.pickupMinDistance = Infinity;
    this.dropoffMinDistance = Infinity;
    this.initialPositionToPickupDistance = null;
    this.pickupToDropoffDistance = null;
    this.pickupMinDistancePosition = null;
    this.startTimestamp = null;
    this.booking = null;
    this.pickupZones = null;
    this.dropoffZones = null;
    this.currentLocation = null;
  }

  /**
   * Berechnet den Radius basierend auf dem Standorttyp.
   */
  calculateRadius(location: { lat: number, lon: number, isAirport: boolean }): number {
    return location.isAirport ? this.liveTrackingValues.AIRPORT_RADIUS_IN_METERS : this.liveTrackingValues.ADDRESS_RADIUS_IN_METERS;
  }

  /**
   * Capitalizes the first letter of a word.
   */
  private capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  private setLogging(): void {
    if (this.logList.includes(+this.sessionQuery.getTdlNr())) {
      this.geolocationService.setConfig({ logLevel: BackgroundGeolocation.LOG_LEVEL_DEBUG });
    } else {
      this.geolocationService.setConfig({ logLevel: BackgroundGeolocation.LOG_LEVEL_OFF });
    }
  }

  /**
   * Überprüft ob der Fahrer auf dem Weg zum Kunden ist
   */
  async checkDriverHeadingToPickup(
    startTimestamp: number,
    traveledDistance: number,
    initialPositionToPickupDistance: number
  ): Promise<boolean> {
    if (this.startTimestamp) {
      //console.log(this.liveTrackingValues);

      const MIN_TRACKING_TIME = this.liveTrackingValues.HEADING_TO_PICKUP_TIME_THRESHOLD * 60 * 1000;
      const elapsedTimeInMilliseconds = differenceInMilliseconds(new Date(), new Date(startTimestamp));

      if (elapsedTimeInMilliseconds < MIN_TRACKING_TIME && !this.liveTrackingQuery.hasReachedPickupLocation()) {
        // console.log(
        //   `Tracking has been active for ${elapsedTimeInMilliseconds}ms. Waiting for at least ${MIN_TRACKING_TIME}ms before evaluation.`
        // );
        return false;
      }

      const ACTIVATION_THRESHOLD = this.liveTrackingValues.HEADING_TO_PICKUP_ACTIVATION_DISTANCE_THRESHOLD * initialPositionToPickupDistance;
      // console.log(
      //   `Traveled Distance: ${traveledDistance} meters, Activation Threshold: ${ACTIVATION_THRESHOLD} meters.`
      // );

      if (traveledDistance < ACTIVATION_THRESHOLD || this.liveTrackingQuery.hasReachedPickupLocation()) {
        // console.log("Driver is moving towards the pickup location.");
        liveTrackingStore.update(setProp('isHeadingToPickup', true));
        await this.geolocationService.setConfig({
          params: {
            'api': 'bookings',
            'mode': 'live_tracking',
            'bookingCode': this.booking.buch_code,
            'tourId': this.booking.tour_id,
            'isHeadingToPickup': true
          }
        });
      }
    }
  }
}

export interface WtGeolocation {
  coordinates: {
    latitude: number,
    longitude: number,
    accuracy: number
  },
  permission: 'reduced' | 'precise',
  timestamp: string
}