import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { BookingDetailsGuard } from './guards/booking-details.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage)
  },
  {
    path: 'logout',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage)
  },
  {
    path: 'booking-master',
    loadComponent: () => import('./pages/booking/booking-master/booking-master.page').then(m => m.BookingMasterPage),
    canActivate: [AuthGuard]
  },
  {
    path: 'booking-detail/:bookingSegment/:bookingId',
    loadComponent: () => import('./pages/booking/booking-detail/booking-detail.page').then(m => m.BookingDetailPage),
    canActivate: [AuthGuard, BookingDetailsGuard],
    canDeactivate: [BookingDetailsGuard]
  },
  {
    path: 'booking-detail/:bookingSegment/:bookingId/:token',
    loadComponent: () => import('./pages/booking/booking-detail/booking-detail.page').then(m => m.BookingDetailPage),
    canActivate: [AuthGuard],
    canDeactivate: [BookingDetailsGuard]
  },
  {
    path: 'package-overview/booking-detail/:bookingSegment/:bookingId',
    loadComponent: () => import('./pages/booking/booking-detail/booking-detail.page').then(m => m.BookingDetailPage),
    canActivate: [AuthGuard],
    canDeactivate: [BookingDetailsGuard]
  },
  {
    path: 'tour-overview/booking-detail/:bookingSegment/:bookingId',
    loadComponent: () => import('./pages/booking/booking-detail/booking-detail.page').then(m => m.BookingDetailPage),
    canActivate: [AuthGuard],
    canDeactivate: [BookingDetailsGuard]
  },
  {
    path: 'transfer-documents',
    loadComponent: () => import('./pages/transfer-documents/transfer-documents.page').then(m => m.TransferDocumentsPage),
    canActivate: [AuthGuard]
  },
  {
    path: 'pickup-notification',
    loadComponent: () => import('./pages/pickup-notification/pickup-notification.page').then(m => m.PickupNotificationPage),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/routes').then(m => m.SETTINGS_ROUTES),
    canActivate: [AuthGuard]
  },
  {
    path: 'pickup-signs',
    loadComponent: () => import('./pages/pickup-signs/pickup-signs.page').then(m => m.PickupSignsPage),
    canActivate: [AuthGuard]
  },
  {
    path: 'help',
    loadComponent: () => import('./pages/help/help.page').then(m => m.HelpPage),
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    loadComponent: () => import('./pages/notifications/notifications.page').then(m => m.NotificationsPage),
    canActivate: [AuthGuard]
  },
  {
    path: 'incident-master',
    loadComponent: () => import('./pages/incident/incident-master/incident-master.page').then(m => m.IncidentMasterPage),
    canActivate: [AuthGuard]
  },
  {
    path: 'incident-detail/:incidentSegment/:bookingId',
    loadComponent: () => import('./pages/incident/incident-detail/incident-detail.page').then(m => m.IncidentDetailPage),
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user-management/routes').then(m => m.USER_ROUTES),
    canActivate: [AuthGuard]
  },
  {
    path: 'contractors-profile',
    loadChildren: () => import('./pages/contractors-profile/routes').then(m => m.CONTRACTOR_PROFILE_ROUTES),
    canActivate: [AuthGuard]
  },
  {
    path: 'package-overview/:bookingSegment/:packageId',
    loadComponent: () => import('./pages/booking/package-overview/package-overview.page').then(m => m.PackageOverviewPage),
    canActivate: [AuthGuard]
  },
  {
    path: 'tour-overview/:bookingSegment/:tourId',
    loadComponent: () => import('./pages/booking/package-overview/package-overview.page').then(m => m.PackageOverviewPage),
    canActivate: [AuthGuard]
  },
  {
    path: 'prices',
    loadChildren: () => import('./pages/prices/routes').then( m => m.PRICE_ROUTES),
    canActivate: [AuthGuard]
  },
  {
    path: 'permissions',
    loadComponent: () => import('./pages/permissions/permissions.page').then( m => m.PermissionsPage)
  },
  {
    path: '**',
    redirectTo: 'booking-master'
  }
];
