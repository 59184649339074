import { Injectable, NgZone } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushProvider } from '../push/push';
import { Router } from '@angular/router';
import { DialogService } from '../dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular/standalone';
import { firebaseConfig } from 'src/environments/environment';
import {
    FirebaseMessaging,
    GetTokenOptions,
    PermissionStatus,
} from "@capacitor-firebase/messaging";
import { Badge } from '@capawesome/capacitor-badge';
import { ActionPerformed, LocalNotifications } from '@capacitor/local-notifications';
import { Device, DeviceId } from '@capacitor/device';

@Injectable({
    providedIn: 'root'
})
export class FcmService {

    deviceId: DeviceId;

    constructor(
        private push: PushProvider,
        private router: Router,
        private ngZone: NgZone,
        private dialogs: DialogService,
        private translate: TranslateService,
        private platform: Platform
    ) {
        Device.getId().then((value) => { this.deviceId = value });
    }

    async initPush() {
        let isSupported = (await FirebaseMessaging.isSupported()).isSupported;

        if (isSupported) {
            const pushPermission = (await FirebaseMessaging.checkPermissions()).receive;

            if (pushPermission === 'granted') {
                FirebaseMessaging.removeAllListeners().then(() => {
                    FirebaseMessaging.addListener('tokenReceived', (event) => {
                        this.ngZone.run(() => {
                            let token = event.token;

                            if (token) {
                                this.push.saveToken(token, this.deviceId.identifier).subscribe();
                            }
                        });
                    });

                    if (Capacitor.isNativePlatform()) {
                        FirebaseMessaging.addListener('notificationReceived', (event) => {
                            this.ngZone.run(async () => {
                                if (this.platform.is('android')) {
                                    LocalNotifications.schedule({
                                        notifications: [
                                            {
                                                title: event.notification.title,
                                                body: event.notification.body,
                                                id: Number(Date.now().toString().slice(4)),
                                                extra: event.notification.data,
                                                smallIcon: 'push_icon_name'
                                            },
                                        ],
                                    });
                                }
                            });
                        });
                        FirebaseMessaging.addListener('notificationActionPerformed', (event) => {
                            this.ngZone.run(() => {
                                const data = event.notification.data

                                this.openLinkedPage(data);
                                this.clearAllMessages();
                            });
                        });

                        if (this.platform.is('android')) {
                            LocalNotifications.addListener('localNotificationActionPerformed',
                                (localNotificatio: ActionPerformed) => {
                                    this.ngZone.run(async () => {
                                        const data = localNotificatio.notification.extra;

                                        this.openLinkedPage(data);
                                        this.clearAllMessages();
                                    });
                                });
                        }
                    }
                });
            }
        }
    }

    public async registerPush(requestPermission: boolean) {
        let isSupported = (await FirebaseMessaging.isSupported()).isSupported;
        let token: string = null;

        if (isSupported) {
            this.clearAllMessages();

            if (requestPermission) {
                const permission = (await FirebaseMessaging.requestPermissions()).receive;

                if (Capacitor.isNativePlatform()) {
                    if (permission === 'granted') {
                        token = (await FirebaseMessaging.getToken()).token;
                    }
                } else {
                    if (permission !== "denied") {
                        const options: GetTokenOptions = {
                            vapidKey: firebaseConfig.vapidKey,
                        };
                        token = (await FirebaseMessaging.getToken(options)).token;
                    }
                }
            } else {
                if (Capacitor.isNativePlatform()) {
                    token = (await FirebaseMessaging.getToken()).token;
                } else {
                    const options: GetTokenOptions = {
                        vapidKey: firebaseConfig.vapidKey,
                    };
                    token = (await FirebaseMessaging.getToken(options)).token;
                }
            }

            if (token) {
                this.push.saveToken(token, this.deviceId.identifier).subscribe();
            }
        }
    }

    public clearAllMessages() {
        this.ngZone.run(async () => {
            let isSupported = (await FirebaseMessaging.isSupported()).isSupported;

            if (isSupported) {
                if (Capacitor.isNativePlatform()) {
                    await FirebaseMessaging.removeAllDeliveredNotifications();
                }

                const badgeSupported = (await Badge.isSupported()).isSupported;
                if (badgeSupported) {
                    const badgePermission = Badge.checkPermissions();

                    if (badgePermission) {
                        await Badge.clear();
                    }
                }
                this.push.setAllMessagesToRead().subscribe();
            }
        });
    }

    openLinkedPage(data: any) {
        if (data.mode) {
            switch (data.mode) {
                case 'booking':
                    this.router.navigateByUrl('/booking-detail/' + data.state + '/' + data.booking_code);
                    break;
                case 'incident':
                    this.router.navigateByUrl('/incident-detail/' + data.state + '/' + data.booking_code);
                    break;
            }
        }
    }

    async getPermission() {
        let isSupported = (await FirebaseMessaging.isSupported()).isSupported;
        if (isSupported) {
            return (await FirebaseMessaging.checkPermissions()).receive;
        }

        return false;
    }

    async deleteToken() {
        let isSupported = (await FirebaseMessaging.isSupported()).isSupported;

        if (isSupported) {
            await FirebaseMessaging.deleteToken();
            this.push.deleteToken(this.deviceId.identifier).subscribe();
        }
    }
}
