import { fromUnixTime } from "date-fns";

export class Feedback {
    public bewertung: number;
    public kommentar: string;
    public datum: Date;

    constructor(bewertung: number, kommentar: string, datum: string) {
        this.bewertung = bewertung;
        this.kommentar = kommentar;
        this.datum = fromUnixTime(Number(datum));
    }

    public getBewertung(): number {
        return this.bewertung;
    }
}
