import { Injectable } from '@angular/core';
import { liveTrackingStore, LiveTrackingState } from './live-tracking.store';
import { select } from '@ngneat/elf';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LiveTrackingQuery {
  isLiveTrackingActive$ = liveTrackingStore.pipe(select(state => !!state.isLiveTrackingActive));
  isRead$ = liveTrackingStore.pipe(select(state => state.isGeolocationReady));
  state$ = liveTrackingStore.pipe(select(state => state));
  hasReachedPickupLocation$ = liveTrackingStore.pipe(select(state => state.hasReachedPickupLocation));
  hasReachedDropoffLocation$ = liveTrackingStore.pipe(select(state => state.hasReachedDropoffLocation));
  hasLeftPickupLocation$ = liveTrackingStore.pipe(select(state => state.hasLeftPickupLocation));
  noShowButtonClicked$ = liveTrackingStore.pipe(
    select(state => state.nowShowEventTriggered),
    filter(value => value === true)
  );
  hasAutomaticEvents$ = liveTrackingStore.pipe(select(state => state.hasAutomaticEvents));
  isInDropoffZone$ = liveTrackingStore.pipe(select(state => state.isInDropoffZone));

  constructor() { }

  isReady(): boolean {
    return liveTrackingStore.getValue().isGeolocationReady;
  }
  isLiveTrackingActive(): boolean {
    return liveTrackingStore.getValue().isLiveTrackingActive;
  }
  hasReachedPickupLocation(): boolean {
    return liveTrackingStore.getValue().hasReachedPickupLocation;
  }
  hasReachedDropoffLocation(): boolean {
    return liveTrackingStore.getValue().hasReachedDropoffLocation;
  }
  hasLeftPickupLocation(): boolean {
    return liveTrackingStore.getValue().hasLeftPickupLocation;
  }
  hasLeftDropoffLocation(): boolean {
    return liveTrackingStore.getValue().hasLeftDropoffLocation;
  }
  getActiveBookingCode(): string {
    return liveTrackingStore.getValue().activeBookingCode;
  }
  killSwitchActivated(): boolean {
    return liveTrackingStore.getValue().killSwitchActivated;
  }
  pickupLeftDetectionActivated(): boolean {
    return liveTrackingStore.getValue().pickupLeftDetectionActivated;
  }
  dropoffLeftDetectionActivated(): boolean {
    return liveTrackingStore.getValue().dropoffLeftDetectionActivated;
  }
  isHeadingToPickup(): boolean {
    return liveTrackingStore.getValue().isHeadingToPickup;
  }
}