import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular/standalone';
import { Storage } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    private _storage: Storage | null = null;

    constructor(
        private storage: Storage,
        private platform: Platform
    ) { }

    async init() {
        if (this._storage != null) {
            return;
        }

        if (this.platform.is('hybrid')) {
            await this.storage.defineDriver(CordovaSQLiteDriver);
        }

        const storage = await this.storage.create();
        this._storage = storage;
    }

    public async set(key: string, value: any): Promise<any> {
        await this.init();
        return await this._storage?.set(key, value);
    }

    public async get(key: string): Promise<any> {
        await this.init();
        return await this._storage?.get(key);
    }

    public async forEach() {
        await this.init();
        var returnArray = [];

        await this._storage.forEach((value, key) => {
            returnArray.push({ key, value });
        });

        return returnArray;
    }
}
