import { BookingDetails } from './bookingDetails';
import { BookingProgress } from 'src/app/interfaces/bookingProgress.interface';
import { BookingDiversion } from './bookingDiversion';
import { parse } from 'date-fns';

export class Booking {

  public vorlaufzeit: number;
  public buch_code: string;
  public buch_id: string;
  public voucher_id: string;
  public unternehmer_id: number;
  public transferdatum: string;
  public transferdatum_local: string;
  public abholzeit: string;
  public abholzeit_local: string;
  public transferdatum_stamp: number;
  public buchungsdatum: string;
  public buchungsdatum_stamp: number;
  public ext_identifier: number;
  public storniert: string;
  public von: string;
  public nach: string;
  public start_iata: string;
  public ziel_iata: string;
  public von_bezeichnung: string;
  public von_ort: string;
  public von_plz: string;
  public von_strasse: string;
  public von_ortsteil: string;
  public von_land: string;
  public nach_bezeichnung: string;
  public nach_ort: string;
  public nach_plz: string;
  public nach_strasse: string;
  public nach_land: string;
  public pickup_punkt: string;
  public dropoff_punkt: string;
  public wagenklasse: string;
  public wagentyp: string;
  public max_anzahl_pers: number;
  public anzahl_pers: number;
  public fahrer_id;
  public fahrer_status;
  public preis: number;
  public prov_auszahlung: string;
  public prov_auszahlung_steuer: number;
  public zub_option: string;
  public poolfahrt: string;
  public pinanfrage: string;
  public vip_status: string;
  public is_abgelehnt: string;
  public roletype_ident: number;
  public roletype_klartext: string;
  public prov_agentur_nr: string;
  public firmentarif: string;
  public logo_agentur_nr: string;
  public details: BookingDetails;
  public zahlart: string;
  public package_id: string;
  public tour_id: string;
  public progress: BookingProgress;
  public isPreferredPartnerBooking: boolean;
  public preferredPartnerUntil: string;
  public diversion: BookingDiversion;

  constructor(jsonObject?) {
    if (jsonObject) {
      this.vorlaufzeit = jsonObject['vorlaufzeit'];
      this.buch_code = jsonObject['buch_code'];
      this.buch_id = jsonObject['buch_id'];
      this.voucher_id = jsonObject['voucher_id'];
      this.unternehmer_id = jsonObject['unternehmer_id'];
      this.transferdatum = jsonObject['transferdatum'];
      this.abholzeit = jsonObject['abholzeit'];
      this.transferdatum_stamp = jsonObject['transferdatum_stamp'];
      this.buchungsdatum = jsonObject['buchungsdatum'];
      this.buchungsdatum_stamp = jsonObject['buchungsdatum_stamp'];
      this.ext_identifier = jsonObject['ext_identifier'];
      this.storniert = jsonObject['storniert'];
      this.von = jsonObject['von'];
      this.nach = jsonObject['nach'];
      this.start_iata = jsonObject['start_iata'];
      this.ziel_iata = jsonObject['ziel_iata'];
      this.von_bezeichnung = jsonObject['von_bezeichnung'];
      this.von_ort = jsonObject['von_ort'];
      this.von_plz = jsonObject['von_plz'];
      this.von_strasse = jsonObject['von_strasse'];
      this.von_ortsteil = jsonObject['von_ortsteil'];
      this.von_land = jsonObject['von_land'];
      this.nach_bezeichnung = jsonObject['nach_bezeichnung'];
      this.nach_ort = jsonObject['nach_ort'];
      this.nach_plz = jsonObject['nach_plz'];
      this.nach_strasse = jsonObject['nach_strasse'];
      this.von_ortsteil = jsonObject['nach_ortsteil'];
      this.nach_land = jsonObject['nach_land'];
      this.pickup_punkt = jsonObject['pickup_punkt'];
      this.dropoff_punkt = jsonObject['dropoff_punkt'];
      this.wagenklasse = jsonObject['wagenklasse'];
      this.wagentyp = jsonObject['wagentyp'];
      this.max_anzahl_pers = jsonObject['max_anzahl_pers'];
      this.anzahl_pers = jsonObject['anzahl_pers'];
      this.fahrer_id = jsonObject['fahrer_id'];
      this.fahrer_status = jsonObject['fahrer_status'];
      this.preis = jsonObject['preis'];
      this.prov_auszahlung = jsonObject['prov_auszahlung'];
      this.prov_auszahlung_steuer = +jsonObject['prov_auszahlung_steuer'];
      this.zub_option = jsonObject['zub_option'];
      this.poolfahrt = jsonObject['poolfahrt'];
      this.pinanfrage = jsonObject['pinanfrage'];
      this.vip_status = jsonObject['vip_status'];
      this.is_abgelehnt = jsonObject['is_abgelehnt'];
      this.roletype_ident = +jsonObject['roletype_ident'];
      this.roletype_klartext = jsonObject['roletype_klartext'];
      this.prov_agentur_nr = jsonObject['prov_agentur_nr'];
      this.firmentarif = jsonObject['firmentarif'];
      this.logo_agentur_nr = jsonObject['logo_agentur_nr'];
      this.details = new BookingDetails(jsonObject['details']);
      this.zahlart = jsonObject['zahlart'];
      this.progress = Number(jsonObject['progress']);
      this.tour_id = jsonObject['tour_id'];
      this.package_id = jsonObject['package_id'];
      this.isPreferredPartnerBooking = jsonObject['isPreferredPartnerBooking'];
      this.preferredPartnerUntil = jsonObject['preferredPartnerUntil'];

      if (jsonObject['diversion']) {
        this.diversion = new BookingDiversion(jsonObject['diversion']);
      }

      if (this.storniert !== '0000-00-00 00:00:00') {

        this.roletype_ident = 3;
        this.roletype_klartext = 'CXL';
      }

      this.globalizeDateTime(jsonObject['transferdatum'], jsonObject['abholzeit']);      
    }
  }

  public getTransferDate() {
    return new Date(this.transferdatum.replace(' ', 'T'));
  }

  public getBookingDate() {
    return new Date(this.buchungsdatum.replace(' ', 'T'));
  }

  public getPrio() {
    return this.roletype_ident;
  }

  public isDeclined(): boolean {

    if (this.is_abgelehnt === '1') {
      return true;
    }

    return false;
  }

  public isAssigned(): boolean {

    if (this.fahrer_id != null) {
      return true;
    }

    return false;
  }

  public getBookingCode() {
    return this.buch_code;
  }

  public getSla() {
    return this.details.SLAs;
  }

  private globalizeDateTime(transferDateStr: string, transferTimeStr: string) {
    const dateFormat1 = 'yyyy-MM-dd';
    const dateFormat2 = 'dd.MM.yyyy';
    let transferDate: Date;
  
    try {
      transferDate = parse(transferDateStr, dateFormat1, new Date());
    } catch {
      transferDate = parse(transferDateStr, dateFormat2, new Date());
    }

    const transferTime = parse(transferTimeStr, 'HH:mm:ss', new Date());
  
    this.transferdatum_local = transferDate.toLocaleDateString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    this.abholzeit_local = transferTime.toLocaleTimeString('default', {
      hour: 'numeric',
      minute: 'numeric',
    });
  }

  public hasPackageData() {

    return this.package_id ? true : false;
  }

  public hasTourData() {

    return this.tour_id ? true : false;
  }
}

export interface BookingElement {
  bookings: Booking[];
  date: string;
  date_clean: string;
}
export { BookingProgress };

