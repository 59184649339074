import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

export enum LogLevel {
  Error,
  Warning,
  Info,
  Debug,
}

@Injectable({
  providedIn: 'root'
})
export class LoggingProvider {

  constructor() {
  }

  public debug(...objects: any[]) {
    this.log(console.log, LogLevel.Debug, objects);
  }

  public error(...objects: any[]) {
    this.log(console.error, LogLevel.Error, objects);
  }

  private log(func: Function, level: LogLevel, objects: any[]) {
    if (environment.production !== true || (environment.production === true && level === LogLevel.Error)) {
      func.apply(console, objects);
    }
  }

}
