import { inject, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Geolocation, Position, PermissionStatus, PositionOptions } from '@capacitor/geolocation';
import { PermissionStatus as WtPermissionStatus, WT_Permission } from '@german-transfer/wt-permission';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  public permission: WtPermissionStatus;
  public geolocationPermission: PermissionStatus;
  public ready: boolean = false;

  constructor() { }

  async getCurrentGeolocation() {
    let permission: PermissionStatus = await Geolocation.checkPermissions();
    let coordinates;

    if ((permission.coarseLocation === 'prompt' || permission.coarseLocation === 'prompt-with-rationale') && Capacitor.isNativePlatform()) {
      try {
        permission = await Geolocation.requestPermissions({
          permissions: ['location']
        });
      } catch(error) {
      }
    }
      
    if (permission.coarseLocation !== 'denied') {
      try {
        const getPos = async (options: PositionOptions = {}): Promise<Position> => {
          return new Promise<Position>(async (resolve, reject) => {
            const id = await Geolocation.watchPosition(options, (position, err) => {
              Geolocation.clearWatch({id});
              if(err) {
                reject(err);
                return;
              }
              resolve(position);
            });
          });
        };
        coordinates = await getPos({
          enableHighAccuracy: true
        });
      } catch (error) {
        this.geolocationPermission = {'coarseLocation': 'denied', 'location': 'denied'};
      }
    }
        
    return {
      coordinates,
      permission
    };
  }

  async checkPermission() {
    this.geolocationPermission = await Geolocation.checkPermissions();
    this.permission = await WT_Permission.checkPermissions();
    return this.permission
  }

  async requestLocationPermission() {
    const state = await WT_Permission.requestLocationPermissions();
    return state;
    
  }

  async requestMotionPermission() {
    const state = await WT_Permission.requestMotionPermission();
    return state;
    
  }
}
