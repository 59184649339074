<ion-app>
  <ion-split-pane when="lg" contentId="main">
    <ion-menu disabled contentId="main" menuId="sideMenu">
      <ion-header>
        <ion-toolbar>
          <ion-title color="primary">{{'menu.header' | translate}}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <div class="menu-wrapper">
          <ion-list id="profileList" lines="none">
            <ion-menu-toggle auto-hide="false">
              <ion-item routerDirection="root" routerLink="/contractors-profile" routerLinkActive="active"
                [disabled]="networkStatus$ | async" detail="false" class="no-ripple profile-click"
                (click)="handleProfileClick($event)">
                <div class="profile-item-content">
                  <div class="lucide-avatar">
                    <lucide-icon [img]="CircleUser"></lucide-icon>
                  </div>
                  <div class="profile-labels">
                    <b>{{ userName }} <ion-badge id="isContactEmailDeadBadge" *ngIf="isContactEmailDead$ | async"
                        color="danger">!</ion-badge></b>
                    @if (userType === 'operator') {
                    <span>{{ 'driver.profile.operator' | translate }}</span>
                    } @else {
                    <span>{{ 'driver.profile.driver' | translate }}</span>
                    }
                  </div>
                </div>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
          <ion-list lines="none" class="menu-list">
            <ion-menu-toggle auto-hide="false">
              <ion-item routerDirection="root" routerLink="/booking-master" routerLinkActive="active">
                <div slot="start" class="lucide-wrapper">
                  <lucide-icon [img]="Book"></lucide-icon>
                </div>
                <ion-label>
                  {{'menu.bookings' | translate}}
                </ion-label>
              </ion-item>
              <ion-item routerDirection="root" routerLink="/transfer-documents" routerLinkActive="active"
                [disabled]="networkStatus$ | async">
                <div slot="start" class="lucide-wrapper">
                  <lucide-icon [img]="FileText"></lucide-icon>
                </div>
                <ion-label>
                  {{'menu.transfer_documents' | translate}}
                </ion-label>
              </ion-item>
              <ion-item *appHasPermission="'operator'" routerDirection="root" routerLink="/user"
                routerLinkActive="active" [disabled]="networkStatus$ | async">
                <div slot="start" class="lucide-wrapper">
                  <lucide-icon [img]="Users"></lucide-icon>
                </div>
                <ion-label>
                  {{'menu.user' | translate}}
                </ion-label>
              </ion-item>
              <ion-item routerDirection="root" routerLink="/pickup-notification" routerLinkActive="active"
                [disabled]="networkStatus$ | async">
                <div slot="start" class="lucide-wrapper">
                  <lucide-icon [img]="Clock"></lucide-icon>
                </div>
                <ion-label>
                  {{'menu.pickup_notifications' | translate}}
                </ion-label>
              </ion-item>
              <ion-item routerDirection="root" routerLink="/incident-master" routerLinkActive="active"
                [disabled]="networkStatus$ | async">
                <div slot="start" class="lucide-wrapper">
                  <lucide-icon [img]="AlertTriangle"></lucide-icon>
                </div>
                <ion-label>
                  {{'menu.noshows' | translate}}
                </ion-label>
                <ion-badge id="complaintCounterBadge" *ngIf="(complaintCount$ | async) > 0" color="danger">{{
                  complaintCount$ | async }}</ion-badge>
              </ion-item>
              <ion-item class="ion-hide" routerDirection="root" routerLink="/notifications" routerLinkActive="active"
                [disabled]="networkStatus$ | async">
                <ion-icon slot="start" src="./assets/icon/envelope-solid.svg"></ion-icon>
                <ion-label>
                  {{'menu.notifications' | translate}}
                </ion-label>
              </ion-item>
              <ion-item button (click)="openDocumentation()" [disabled]="networkStatus$ | async">
                <div slot="start" class="lucide-wrapper">
                  <lucide-icon [img]="HelpCircle"></lucide-icon>
                </div>
                <ion-label>
                  {{'menu.guide' | translate}}
                </ion-label>
              </ion-item>
              <ion-item routerDirection="root" routerLink="/pickup-signs" routerLinkActive="active"
                [disabled]="networkStatus$ | async">
                <div slot="start" class="lucide-wrapper">
                  <lucide-icon [img]="SignpostBig"></lucide-icon>
                </div>
                <ion-label>
                  {{'menu.pickup_sign' | translate}}
                </ion-label>
              </ion-item>
              <ion-item *appHasPermission="'operator'" routerDirection="root" routerLink="/prices"
                routerLinkActive="active" [disabled]="networkStatus$ | async">
                <div slot="start" class="lucide-wrapper">
                  <lucide-icon [img]="DollarSign"></lucide-icon>
                </div>
                <ion-label>
                  {{'menu.prices' | translate}}
                </ion-label>
              </ion-item>
              <ion-item routerDirection="root" routerLink="/settings" routerLinkActive="active">
                <div slot="start" class="lucide-wrapper">
                  <lucide-icon [img]="Settings"></lucide-icon>
                </div>
                <ion-label>
                  {{'menu.settings' | translate}}
                </ion-label>
              </ion-item>
              <ion-item routerDirection="root" routerLink="/logout">
                <div slot="start" class="lucide-wrapper">
                  <lucide-icon [img]="LogOut"></lucide-icon>
                </div>
                <ion-label>
                  {{'menu.logout' | translate}}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
          <div id="appVersion">
            V{{ appVersion }}
            @if (!environment.production) {
            <br /><span>B{{ buildNumber }}</span>
            }
          </div>
        </div>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>