import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertButton, NavController, Platform } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { DialogService } from '../services/dialog/dialog.service';
import { SessionQuery } from '../state/session/session.query';
import { SessionService } from '../state/session/session.service';
import * as Sentry from "@sentry/capacitor/";
import { Browser } from '@capacitor/browser';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private translate: TranslateService,
        private dialog: DialogService,
        private sessionQuery: SessionQuery,
        private navCtrl: NavController,
        private session: SessionService,
        public platform: Platform) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.url.includes('./assets/i18n/')) {
            return next.handle(req);
        } 

        return next.handle(req).pipe(
            timeout(120000),
            catchError((error) => {
                if (error.name === 'TimeoutError') {
                    this.translate.get(['interceptors.http_error.timeout_title', 'interceptors.http_error.timeout_message']).subscribe(values => {
                        this.showErrorDialog(error,
                            values['interceptors.http_error.timeout_title'],
                            values['interceptors.http_error.timeout_message']
                        );
                    });
                    return EMPTY;
                } else if (this.sessionQuery.getSettingsValue('isOffline') === 'true') {
                    this.dialog.showAlert({ header: this.translate.instant('interceptors.offline.title'), message: this.translate.instant('interceptors.offline.message'), buttons: ['OK'] });
                } else {
                    if (error.error) {
                        this.handleMetaError(error);
                    }
                }
                return throwError(error);
            })
        );
    }

    async showErrorDialog(error: HttpErrorResponse, title: string, message: string) {
        const buttons: AlertButton[] = [{ text: 'OK' }];
        const moreInfoUrl = error.error.meta && error.error.meta.moreInfoUrl ? error.error.meta.moreInfoUrl : null;
        if (moreInfoUrl) {
            buttons.push({
                text: 'Weitere Infos',
                handler: () => {
                    this.openMoreInformationPage(moreInfoUrl);
                }
            });
        }
        this.dialog.showAlert({ 
            header: title != null ? title : error.name,
            message,
            buttons
        });
    }

    handleMetaError(error: HttpErrorResponse) {
        const meta = error.error.meta;
        this.translate.get([
            'interceptors.http_error.error_dialog_title', 
            'interceptors.http_error.general_error_message'
        ]).subscribe(values => {
            const title = meta?.title || values['interceptors.http_error.error_dialog_title'];
            const message = meta?.userMessage || values['interceptors.http_error.general_error_message'];
            this.showErrorDialog(error, title, message);
        });

        if (error.status === 401) {
            this.session.logout();
            this.navCtrl.navigateRoot('/login');
        } else {
            Sentry.addBreadcrumb({
                type: 'error',
                category: 'http',
                data: error
            });
            this.sendErrorToSentry(error);
        }
    }

    sendErrorToSentry(error: HttpErrorResponse) {
        Sentry.captureException(error);
    }

    openMoreInformationPage(url: string) {
        if (this.platform.is('ios') && this.platform.is('mobileweb')) {
            window.open(url);
        } else {
            Browser.open({
                url: url
            });
        }
    }   

}
