import { parse } from 'date-fns';

export class BookingDetails {

  public aenderungen: string;
  public aenderungen_id: string;
  public transferart_start: string;
  public transferart_ziel: string;
  public anzahl_pers: number;
  public fahrgastname: string;
  public fahrgastname_telefon: string;
  public ankunftszeit: string;
  public abflugzeit: string;
  public flugnr: string;
  public flugsrc: string;
  public flugdest: string;
  public bemerkungen: string;
  public meeting_point_info: string;
  public entfernung: number;
  public extras: string;
  public SLAs: any;
  public agb_link: string;
  public abholschild_link: string;
  public enable_abholzeitmitteilung: number;
  public abzeit_bereits_mitget: number;
  public geoData: string;
  public babysitzschale: number;
  public kindersitze: number;
  public kinderpolster: number;
  public begleitservice: number;
  public notfallnummer: string;
  public ext_identifier: string;
  public noshow_gesendet: boolean;
  public ta_id: string;
  public ta_name: string;
  public ta_telefon: string;

  constructor(detailObject) {

    if (detailObject === undefined) {
      detailObject = '';
    }

    this.aenderungen = detailObject['aenderungen'];
    this.aenderungen_id = detailObject['aenderungen_id'];
    this.transferart_start = detailObject['transferart_start'];
    this.transferart_ziel = detailObject['transferart_ziel'];
    this.anzahl_pers = detailObject['anzahl_pers'];
    this.fahrgastname = detailObject['fahrgastname'];
    this.fahrgastname_telefon = detailObject['fahrgastname_telefon'];

    if (detailObject['ankunftszeit']) {
      this.ankunftszeit = parse(detailObject['ankunftszeit'], 'HH:mm', new Date()).toLocaleTimeString('default', { hour: 'numeric', minute: 'numeric' });  
    }
    if (detailObject['abflugzeit']) {
      this.abflugzeit = parse(detailObject['abflugzeit'], 'HH:mm', new Date()).toLocaleTimeString('default', { hour: 'numeric', minute: 'numeric' });
    }
    
    this.flugnr = detailObject['flugnr'];
    this.flugsrc = detailObject['flugsrc'];
    this.flugdest = detailObject['flugdest'];
    this.bemerkungen = detailObject['bemerkungen'] === ' ' || detailObject['bemerkungen'] === '' ? undefined : detailObject['bemerkungen'];
    this.meeting_point_info = detailObject['meeting_point_info'];
    this.entfernung = Math.round(detailObject['entfernung'] ? detailObject['entfernung'] : 0);
    this.extras = detailObject['extras'];
    this.SLAs = detailObject['SLAs'];
    this.agb_link = detailObject['agb_details_link'];
    this.abholschild_link = detailObject['abholschild_link'];
    this.enable_abholzeitmitteilung = detailObject['enable_abholzeitmitteilung'];
    this.abzeit_bereits_mitget = detailObject['abzeit_bereits_mitget'];
    this.geoData = detailObject['geodata'];
    this.babysitzschale = +detailObject['babysitzschale'];
    this.kindersitze = +detailObject['kindersitze'];
    this.kinderpolster = +detailObject['kinderpolster'];
    this.begleitservice = +detailObject['begleitservice'];
    this.babysitzschale = +detailObject['babysitzschale'];
    this.notfallnummer = detailObject['notfallnr'];
    this.ext_identifier = detailObject['ext_identifier'];
    this.noshow_gesendet = detailObject['noshow_gesendet'];
    this.ta_id = detailObject['ta_id'];
    this.ta_name = detailObject['ta_name'];
    this.ta_telefon = detailObject['ta_telefon'];    
  }
}
