export class Contractor {

  public id: number;
  public anrede: string;
  public name: string;
  public nachname: string;
  public vorname: string;
  public firma: string;
  public strasse: string;
  public plz: number;
  public ort: string;
  public land: string;
  public telefon: string;
  public telefonWeiterleitung: string;
  public telefonWarteschleife: string;
  public telefonIntern: string;
  public fax: string;
  public mobil: string;
  public email: string;
  public emailWeiterleitung: string;
  public zahlungsart: string;
  public bank: string;
  public blz: string;
  public konto: string;
  public kontoinhaber: string;
  public steuerbefreit: boolean;
  public steuerNr: string;
  public ust_nr: string;
  public maxBookingRestriction: number;

  init(jsonData: {}) {

    this.id = jsonData['id'];
    this.anrede = jsonData['title'];
    this.name = jsonData['name'];
    this.nachname = jsonData['surname'];
    this.vorname = jsonData['forname'];
    this.firma = jsonData['company'];
    this.strasse = jsonData['street'];
    this.plz = jsonData['postcode'];
    this.ort = jsonData['city'];
    this.land = jsonData['country'];
    this.telefon = jsonData['phone'];
    this.telefonWeiterleitung = jsonData['phoneForwarding'];
    this.telefonWarteschleife = jsonData['phoneQueue'];
    this.telefonIntern = jsonData['phoneInternal'];
    this.fax = jsonData['fax'];
    this.mobil = jsonData['mobil'];
    this.email = jsonData['email'];
    this.emailWeiterleitung = jsonData['emailForwarding'];
    this.zahlungsart = jsonData['paymentMethod'];
    this.bank = jsonData['bank'];
    this.blz = jsonData['sortCode'];
    this.konto = jsonData['account'];
    this.kontoinhaber = jsonData['accountHolder'];
    this.steuerbefreit = jsonData['taxExempt'];
    this.steuerNr = jsonData['taxNumber'];
    this.ust_nr = jsonData['salesTaxNumber'];
    this.maxBookingRestriction = jsonData['maxBookingRestriction'];

    return this;
  }
}

