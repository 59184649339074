import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { SessionQuery } from '../state/session/session.query';
import { PermissionLevel } from '../state/user/user.model';

@Directive({
    selector: '[appHasPermission]',
    standalone: true
})
export class HasPermissionDirective implements OnInit, OnDestroy {
  @Input('appHasPermission') permissionLevel: string;
  @Input() set appHasPermissionKey(value) {
    this.permissionKey = value;
  }
  private permissionKey: string;

  private subscription: Subscription;

  constructor(
    private sessionQuery: SessionQuery,
    private vcr: ViewContainerRef,
    private tpl: TemplateRef<any>
  ) { }

  ngOnInit() {
    this.subscription = this.sessionQuery.userPermission$.subscribe(userPermission => {
      if (userPermission || this.permissionKey) {

        const hasPermission = this.sessionQuery.hasPermission(PermissionLevel[this.permissionLevel], this.permissionKey);

        this.vcr.clear();

        if (hasPermission) {
          this.vcr.createEmbeddedView(this.tpl);
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
