import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionQuery } from '../state/session/session.query';

@Injectable()
export class HttpJwtInterceptor implements HttpInterceptor {

  constructor(
    private sessionQuery: SessionQuery) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const jwt = this.sessionQuery.getToken();

    if (jwt) {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + jwt
        }
      });
    }

    return next.handle(req);
  }

}
