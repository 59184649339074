import { createStore, withProps } from '@ngneat/elf';
import { TokenPayload } from 'src/app/interfaces/tokenPayload.interface';

export interface SessionState {
  token?: string;
  tokenPayload?: TokenPayload;
  settings: { [key: string]: any };
  settingsLoaded: boolean;
  complaintCount: number;
  isContactEmailDead: boolean;
}

export const sessionStore = createStore(
  { name: 'session' },
  withProps<SessionState>({
    token: undefined,
    tokenPayload: undefined,
    settings: {},
    settingsLoaded: false,
    complaintCount: 0,
    isContactEmailDead: false,
  })
);