import { createStore, withProps } from '@ngneat/elf';
import { withEntities } from '@ngneat/elf-entities';
import { User } from './user.model';

export interface UserState {
  isUserInit: boolean;
  loading: boolean;
}

export const userStore = createStore(
  { name: 'user' },
  withProps<UserState>({ isUserInit: false, loading: false }),
  withEntities<User>()
);