import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Platform } from '@ionic/angular/standalone';
import { filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../services/dialog/dialog.service';


@Injectable()
export class HttpVersionInterceptor implements HttpInterceptor {

    constructor(
        public injector: Injector,
        private platform: Platform,
        private translate: TranslateService,
        private dialog: DialogService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        req = req.clone({
            setHeaders: {
                AppVersion: environment.version,
                Device: this.getDeviceType()
            }
        });

        return next.handle(req)
            .pipe(
                filter(event => event instanceof HttpResponse),
                map((event: HttpResponse<any>) => {
                    const versionStatus = event.headers.get('X-Version-Status');
                    
                    if (versionStatus === 'outdated') {
                        this.showOutdatedAlert();
                    }
                    return event;
                })
            );
    }

    getDeviceType() {

        const deviceStrings = this.platform.platforms().join(',');

        if (deviceStrings.includes('ios') && deviceStrings.includes('hybrid')) {
            if (deviceStrings.includes('pwa')) {
                return 'ios-pwa';
            }
            if (deviceStrings.includes('mobileweb')) {
                return 'ios-web';
            }
            return 'ios';
        } else if (deviceStrings.includes('android') && deviceStrings.includes('hybrid')) {
            if (deviceStrings.includes('pwa')) {
                return 'android-pwa';
            }
            if (deviceStrings.includes('mobileweb')) {
                return 'android-web';
            }
            return 'android';
        }

        return 'desktop';
    }

    async showOutdatedAlert() {

        this.translate.get(['interceptors.version.alert_title', 'interceptors.version.alert_message']).subscribe(async values => {
            this.dialog.showAlert({
                header: values['interceptors.version.alert_title'],
                message: values['interceptors.version.alert_message']
            });
        });
    }
}
