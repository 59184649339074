export interface User {
  id: number | string;
  gtuNr?: string;
  name?: string;
  username: string;
  password?: string;
  user_level: string;
  active?: boolean;
  forname: string;
  surname: string;
  mobile: string;
  email?: string;
  photoAsBase64?: string;
  bookingStatus?: string;
}

export interface UserResponse {
  active: boolean;
  forname: string;
  id: number;
  mobile: string;
  name: string;
  surname: string;
  user_level: string;
  username: string;
}

export enum PermissionLevel {
  guest = 1,
  driver = 2,
  commissioner = 3,
  operator = 4,
  world_transfer = 5,
}

export function createUser(params: Partial<User>): User {
  return {
    ...params
  } as User;
}