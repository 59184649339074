export class Pickup {

  private _buch_code: string;
  public get buch_code(): string {
    return this._buch_code;
  }

  private _ab_zeit_min: string;
  public get ab_zeit_min(): string {
    return this._ab_zeit_min;
  }

  private _v_kind: string;
  public get v_kind(): string {
    return this._v_kind;
  }

  private _email: string;
  public get email(): string {
    return this._email;
  }

  constructor(jsonObject) {

    this._buch_code = jsonObject['buch_code'];
    this._ab_zeit_min = jsonObject['ab_zeit_min'];
    this._v_kind = jsonObject['v_kind'];
    this._email = jsonObject['email'];
  }
}
