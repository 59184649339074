import { createAnimation } from '@ionic/core';
import { TransitionOptions } from './transition'

function getIonPageElement(element: HTMLElement) {
  if (element.classList.contains('ion-page')) {
    return element;
  }

  const ionPage = element.querySelector(
    ':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs'
  );
  if (ionPage) {
    return ionPage;
  }

  return element;
}

export function pageTransition(_: HTMLElement, opts: TransitionOptions) {
  const CENTER = '0px';
  const isRTL = (_.ownerDocument as any).dir === 'rtl';
  const OFF_RIGHT = isRTL ? '-99.5%' : '99.5%';
  const OFF_LEFT = isRTL ? '33%' : '-33%';
  const OPACITY = 'opacity';
  const OFF_OPACITY = 0.8;

  const backDirection = opts.direction === 'back';
  const enteringEl = opts.enteringEl;
  const leavingEl = opts.leavingEl;

  const ionPageElement = getIonPageElement(enteringEl);
  const enteringToolbarEle = ionPageElement.querySelector('ion-toolbar');
  const rootTransition = createAnimation();

  rootTransition.addElement(ionPageElement).fill('both').beforeRemoveClass('ion-page-invisible');

  // animate the component itself
  if (backDirection) {
    rootTransition.beforeClearStyles([OPACITY]).easing('cubic-bezier(0.32,0.72,0,1)').fromTo('transform', `translateX(${OFF_LEFT})`, `translateX(${CENTER})`);
  } else {
    rootTransition
      .duration(opts.duration || 540)
      .easing('cubic-bezier(0.32,0.72,0,1)')
      .fromTo('transform', `translateX(${OFF_RIGHT})`, `translateX(${CENTER})`)
      .fromTo('opacity', 0.01, 1);
  }

  // Animate toolbar if it's there
  if (enteringToolbarEle) {
    const enteringToolBar = createAnimation();
    enteringToolBar.addElement(enteringToolbarEle);
    rootTransition.addAnimation(enteringToolBar);
  }

  // setup leaving view
  if (leavingEl && backDirection) {
    // leaving content
    rootTransition.duration(opts.duration || 200);

    const leavingPage = createAnimation();
    leavingPage

      .addElement(getIonPageElement(leavingEl))
      .onFinish((currentStep) => {
        if (currentStep === 1 && leavingPage.elements.length > 0) {
          leavingPage.elements[0].style.setProperty('display', 'none');
        }
      })
      .fromTo('transform', `translateX(${CENTER})`, isRTL ? 'translateX(-100%)' : 'translateX(100%)')
      .fromTo(OPACITY, OFF_OPACITY, 1);

    rootTransition.addAnimation(leavingPage);
  }

  return rootTransition;
}