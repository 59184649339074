import { ENVIRONMENT_INITIALIZER, inject, NgZone, ErrorHandler, APP_INITIALIZER, importProvidersFrom, ApplicationConfig } from '@angular/core';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { customTransition } from './transition/transition';
import { BrowserModule } from '@angular/platform-browser';
import { HttpTimezoneInterceptor } from './interceptors/http-timezone.interceptor';
import { HttpVersionInterceptor } from './interceptors/http-version.interceptor';
import { HttpJwtInterceptor } from './interceptors/http-jwt.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient, HttpClient, withInterceptors } from '@angular/common/http';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';
import { LoggingProvider } from './services/logging/logging';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { RequestProvider } from './services/request/request';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { PushProvider } from './services/push/push';
import { DocumentsProvider } from './services/documents/documents';
import { PreferencesProvider } from './services/preferences/preferences';
import { IncidentProvider } from './services/incident/incident';
import { PickupNotificationProvider } from './services/pickup-notification/pickup-notification';
import { ContractorProvider } from './services/contractor/contractor';
import { FeedbackProvider } from './services/feedback/feedback';
import { TransportOrderProvider } from './services/transport-order/transport-order';
import { AgbProvider } from './services/agb/agb';
import { AuthenticationProvider } from './services/authentication/authentication';
import { BookingProvider } from './services/booking/booking';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { PreloadAllModules, RouteReuseStrategy, Router, provideRouter, withPreloading } from '@angular/router';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { routes } from './app.routes';
import { Capacitor } from '@capacitor/core';
import { devTools } from '@ngneat/elf-devtools';
import { httpLanguageInterceptor } from './interceptors/http-language.interceptor';

if (environment.production) {
    devTools();   
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes,withPreloading(PreloadAllModules)),
        importProvidersFrom(BrowserModule, IonicStorageModule.forRoot({
            driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB, Drivers.LocalStorage]
        }), TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }), ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production && !Capacitor.isNativePlatform() })),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        BookingProvider,
        AuthenticationProvider,
        AgbProvider,
        TransportOrderProvider,
        FeedbackProvider,
        ContractorProvider,
        PickupNotificationProvider,
        IncidentProvider,
        PreferencesProvider,
        DocumentsProvider,
        PushProvider,
        LaunchNavigator,
        RequestProvider,
        SocialSharing,
        LoggingProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpJwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpVersionInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpTimezoneInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useValue: createErrorHandler(),
        },
        {
            provide: TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [TraceService],
            multi: true,
        },
        provideHttpClient(
            withInterceptorsFromDi(),
            withInterceptors([httpLanguageInterceptor])),
        provideIonicAngular({
            backButtonText: 'Zurück',
            navAnimation: customTransition,
            innerHTMLTemplatesEnabled: true
        })
    ]
};
