import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SessionQuery } from 'src/app/state/session/session.query';
import { Incident } from '../../models/incident/incident';
import { RequestProvider } from '../request/request';
import { PermissionStatus, Position } from '@capacitor/geolocation';
import { SessionService } from 'src/app/state/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class IncidentProvider {

  public incidentList: any = {
    open: [],
    closed: []
  };

  constructor(public request: RequestProvider,
              public sessionQuery: SessionQuery,
              public sessionService: SessionService) {
  }

  public getIncidentList() {

    return this.request.sendPost({
      api: 'complaints',
      mode: 'list'
    }).pipe(
      map( data => {

        this.incidentList.open = [];
        this.incidentList.closed = [];

        for (let index = 0; index < data['body']['length']; index++) {

          const incident = new Incident(data['body'][index]);

          if (incident.incidentDetails.reaktion === '1') {

            this.incidentList.closed.push(incident);
          } else {

            this.incidentList.open.push(incident);
          }
        }

        return Object.assign([], this.incidentList);
      })
    );
  }

  public getSingleIncident(bookingCode: string) {
    return this.request.sendPost({
      api: 'complaints',
      mode: 'list',
      bookingCode
    }).pipe(
      map( data => {
        return new Incident(data['body'][0]);
      })
    );
  }

  getIncident(bookingId: string, incidentSegment: string) {

    return this.incidentList[incidentSegment].find((incident: Incident) => incident.buch_code === bookingId);
  }

  public sendNoShowReport(buchId: string, position: { coordinates: Position, permission: PermissionStatus}) {

    let geolocation: { permission: any; latitude?: number; longitude?: number; accuracy?: number; };

    if (position.coordinates) {
      geolocation = {
        'latitude': position?.coordinates?.coords.latitude,
        'longitude': position?.coordinates?.coords.longitude,
        'accuracy': position?.coordinates?.coords.accuracy,
        'permission': ''
      };
  
      if (position?.permission.coarseLocation === 'granted') {
        geolocation.permission = 'coarse';
      }
      if (position?.permission.location === 'granted') {
        geolocation.permission = 'precise';
      } 
    }

    return this.request.sendPost({
      api: 'complaints',
      mode: 'noshow_notification',
      buch_id: buchId,
      geolocation: position.coordinates ? JSON.stringify(geolocation) : ''
    }).pipe(
      map(data => data)
    );
  }

  public submitIncidentData(incidentData, imageArray, incidentId) {

      const formData: FormData = new FormData();

      imageArray.forEach(element => {

        formData.append(element.key, element.data, element.name);
      });
      
      formData.append('incidentData', JSON.stringify(incidentData));
      formData.append('api', 'complaints');
      formData.append('mode', 'complaint_send');
      formData.append('id', incidentId);
      formData.append('tdlId', String(this.sessionQuery.getTdlNr()));

      return this.request.sendForm(formData).pipe(
        map(data => {

          this.incidentList.open.forEach((incident, index) => {

            if (incident.id === incidentId) {
              incident.incidentDetails.incidentSubmitted();

              if (index > -1) {
                this.incidentList.closed.push(incident);
                this.incidentList.open.splice(index, 1);
                this.sessionService.getComplaintsCounter();
              }
            }
          });

          return true;
        })
      );
  }

  public loadImages(id: string) {

    return this.request.sendPost({
      api: 'complaints',
      mode: 'load_images',
      rekla_id: id
    }).pipe(
      map(data => data['body'])
    );
  }

  public filterIncident(searchTerm: string, incidentSegment: string) {

    if (searchTerm && searchTerm.trim() !== '') {
      this.incidentList[incidentSegment] = this.incidentList[incidentSegment].filter(item => {
        return (item.buch_code.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1);
      });
    }
  }
}
