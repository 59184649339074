import { createStore, withProps } from '@ngneat/elf';

export interface DriverEventsState {
  driverEventState: number;
  driverEventStrings: { [key: string]: string };
  bookingCode?: string;
}

export const driverEventsStore = createStore(
  { name: 'driver-events' },
  withProps<DriverEventsState>({
    driverEventState: 0,
    driverEventStrings: {},
    bookingCode: undefined,
  })
);