import { CONSTANTS } from './tracking.config';

export class LocationUtils {
    /**
     * Berechnet die Haversine-Distanz zwischen zwei Punkten.
     */
    static haversineDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
        const toRadians = (degrees: number) => degrees * Math.PI / 180;
        const earthRadiusInKM = CONSTANTS.EARTH_RADIUS_IN_KM;

        lat1 = LocationUtils.roundToDecimals(lat1, 6);
        lon1 = LocationUtils.roundToDecimals(lon1, 6);
        lat2 = LocationUtils.roundToDecimals(lat2, 6);
        lon2 = LocationUtils.roundToDecimals(lon2, 6);

        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distanceInMeters = earthRadiusInKM * c * 1000;

        return LocationUtils.roundToDecimals(distanceInMeters, 2);
    }

    static roundToDecimals(value: number, decimals: number): number {
        const factor = Math.pow(10, decimals);
        return Math.round(value * factor) / factor;
    }

    /**
     * Überprüft, ob eine Position innerhalb einer Bounding Box liegt.
     */
    static calculateDelta(radius: number, latitude: number): number {
        const metersPerDegreeLat = 111320; // Durchschnittlicher Wert
        const metersPerDegreeLon = 111320 * Math.cos(latitude * Math.PI / 180);

        const deltaLat = radius / metersPerDegreeLat;
        const deltaLon = radius / metersPerDegreeLon;

        return Math.max(deltaLat, deltaLon);
    }

    /**
     * Berechnet den Delta-Wert basierend auf dem Radius und dem Breitengrad.
     */
    static isWithinBoundingBox(coords: { latitude: number, longitude: number }, boundingBox: BoundingBox): boolean {
        return coords.latitude >= boundingBox.minLat &&
            coords.latitude <= boundingBox.maxLat &&
            coords.longitude >= boundingBox.minLon &&
            coords.longitude <= boundingBox.maxLon;
    }

    /**
     * Berechnet die maximale Entfernung vom Mittelpunkt zur Ecke der Bounding Box.
     */
    static calculateMaxDistanceFromCenter(boundingBox: BoundingBox): number {
        const corners = [
            { lat: boundingBox.minLat, lon: boundingBox.minLon },
            { lat: boundingBox.minLat, lon: boundingBox.maxLon },
            { lat: boundingBox.maxLat, lon: boundingBox.minLon },
            { lat: boundingBox.maxLat, lon: boundingBox.maxLon },
        ];

        const center = boundingBox.center;

        const distances = corners.map(corner => 
            this.haversineDistance(center.lat, center.lon, corner.lat, corner.lon)
        );

        return Math.max(...distances);
    }
}

export interface BoundingBox {
    minLat: number;
    minLon: number;
    maxLat: number;
    maxLon: number;
    center: { lat: number; lon: number };
    maxDistanceFromCenter: number;
}

export interface Zone {
    lat: number;
    lon: number;
    radius: number;
}

export interface ZoneGroup {
    boundingBox: BoundingBox;
    zones: Zone[];
}