import { createStore, withProps } from '@ngneat/elf';

export interface LiveTrackingState {
  isGeolocationReady: boolean;
  isLiveTrackingActive: boolean;
  activeBookingCode?: string;
  hasReachedPickupLocation: boolean;
  hasReachedDropoffLocation: boolean;
  hasLeftPickupLocation: boolean;
  hasLeftDropoffLocation: boolean;
  nowShowEventTriggered: boolean;
  hasAutomaticEvents: boolean;
  killSwitchActivated: boolean;
  pickupLeftDetectionActivated: boolean;
  dropoffLeftDetectionActivated: boolean;
  isHeadingToPickup: boolean;
  isInDropoffZone
}

export const liveTrackingStore = createStore(
  { name: 'live-tracking' },
  withProps<LiveTrackingState>({
    isGeolocationReady: false,
    isLiveTrackingActive: false,
    activeBookingCode: undefined,
    hasReachedPickupLocation: false,
    hasReachedDropoffLocation: false,
    hasLeftPickupLocation: false,
    hasLeftDropoffLocation: false,
    nowShowEventTriggered: false,
    hasAutomaticEvents: false,
    killSwitchActivated: false,
    pickupLeftDetectionActivated: false,
    dropoffLeftDetectionActivated: false,
    isHeadingToPickup: false,
    isInDropoffZone: false
  })
);