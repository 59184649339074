import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpTimezoneInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const CLIENT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return next.handle(request.clone({ setHeaders: { "Client_Timezone": CLIENT_TIMEZONE }}));
  }
}
