import { Injectable } from '@angular/core';
import { sessionStore, SessionState } from './session.store';
import { select } from '@ngneat/elf';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenPayload } from 'src/app/interfaces/tokenPayload.interface';
import { PermissionLevel } from '../user/user.model';

@Injectable({ providedIn: 'root' })
export class SessionQuery {
  isLoggedIn$: Observable<boolean> = sessionStore.pipe(
    select(state => !!state.token && !!state.tokenPayload)
  );

  settingsLoaded$: Observable<boolean> = sessionStore.pipe(
    select(state => state.settingsLoaded)
  );

  userPermission$: Observable<boolean> = sessionStore.pipe(
    select(state => !!state.tokenPayload)
  );

  isBiometricLoginAvailable$: Observable<boolean> = sessionStore.pipe(
    select(state => !!state.settings['loginWithTouch'] && !!state.settings['jwt'])
  );

  networkStatus$: Observable<any> = sessionStore.pipe(
    select(state => state.settings['isOffline'])
  );

  theme$: Observable<any> = sessionStore.pipe(
    select(state => state.settings['theme'])
  );

  complaintcount$: Observable<number> = sessionStore.pipe(
    select(state => state.complaintCount)
  );

  isContactEmailDead$: Observable<boolean> = sessionStore.pipe(
    select(state => state.isContactEmailDead)
  );

  isMenuBadgeVisible$: Observable<boolean> = sessionStore.pipe(
    select(state => state.isContactEmailDead || state.complaintCount > 0)
  );

  constructor() { }

  isLoggedIn(): boolean {
    const state = sessionStore.getValue();
    return !!state.token && !!state.tokenPayload && this.hasPermission(PermissionLevel.driver);
  }

  isBiometricActivated(): boolean | undefined {
    const biometric = sessionStore.getValue().settings['loginWithTouch'];
    return biometric === undefined ? undefined : !!biometric;
  }

  getToken(): string | undefined {
    return sessionStore.getValue().token;
  }

  hasPermission(permissionLevel: PermissionLevel, key?: string): boolean {
    const state = sessionStore.getValue();
    const tokenPayload: TokenPayload | undefined = state.tokenPayload;
    if (!tokenPayload) {
      return false;
    }

    const userPermission: PermissionLevel = PermissionLevel[tokenPayload.userLevel];
    if (key) {
      const settings = state.settings;
      if (userPermission >= permissionLevel) {
        return true;
      } else if (key in settings) {
        return settings[key];
      }
    } else if (userPermission >= permissionLevel) {
      return true;
    }
    return false;
  }

  getSettingsValue(key: string): any {
    return sessionStore.getValue().settings[key];
  }

  isUserTheSessionUser(userId: number): boolean {
    const tokenPayload = sessionStore.getValue().tokenPayload;
    return tokenPayload ? Number(tokenPayload.user_id) === userId : false;
  }

  getUserAccessLevel(): string {
    return sessionStore.getValue().tokenPayload
      ? String(sessionStore.getValue().tokenPayload.userLevel)
      : '';
  }

  getUserId(): number | undefined {
    return sessionStore.getValue().tokenPayload?.user_id;
  }

  getTdlNr(): any {
    return sessionStore.getValue().tokenPayload?.gtu;
  }

  getTokenPayload(): TokenPayload | undefined {
    return sessionStore.getValue().tokenPayload;
  }
}