import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestProvider } from '../request/request';

@Injectable({
  providedIn: 'root'
})
export class AgbProvider {

  constructor(public request: RequestProvider) {
  }

  getAgbJsonData(agencyNumber: number): Observable<any> {

    return this.request.sendGet('./assets/data/agbData/' + agencyNumber + '.json');
  }
}
